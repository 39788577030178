import React from 'react';
import styled from 'styled-components';

const ForConnectDiv = styled.div`
	padding: 80px 0;
	background-color: #034781;
	font-weight: 500;
	font-size: 42px;
	line-height: 50px;
	color: #FFFFFF;
	text-align: center;
	${props => props.$withTopMargin && `margin-top: 80px`};
	${props => props.$withBottomMargin && `margin-bottom: 80px`};
	
	a {
		color: inherit;
		text-decoration: underline;
	}
`;

export const ForConnect = ({children = '', withTopMargin = false, withBottomMargin = false}) => <ForConnectDiv $withTopMargin={withTopMargin} $withBottomMargin={withBottomMargin}>{children}</ForConnectDiv>;