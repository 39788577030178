import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		left: -100px;
	}
	
	::-webkit-scrollbar-track {
		background: #00C0FF;
		border-radius: 2px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.darkBlue};
		border-radius: 2px;
		width: 10px;
	}

	* {
		scrollbar-color: ${props => props.theme.darkBlue} #D5E3F0;
		scrollbar-width: thin;
	}

	svg {
		user-select: none !important;
	}

	html {
		overflow-y: scroll;
		overflow-x: hidden;

		body {
			background: white;
			font-family: 'Rubik', sans-serif;
			position: relative;
			max-width: 100vw;
			width: 100%;
			/* overflow-x: hidden; */

			svg {
				flex-shrink: 0;
			}

			* {
				box-sizing: border-box !important;
				font-family: 'Rubik', sans-serif;
				/* color: #333; */
				/* color: inherit; */
				/* font-family: inherit; */
				transition: 
					color .2s ease-in-out, 
					background-color .2s ease-in-out,
					opacity .2s ease-in-out,
					visibility .2s ease-in-out;
			}

			div#root {
				/* min-height: calc(100vh - 90px); */
				min-height: 100vh;
				height: 100%;
				/* margin-top: 90px; */
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				/* border: 1px solid blue; */

				#header + .container, #add_info + .container {
					margin-top: 40px;
				}

				.forClipboard {
					opacity: 0;
					width: 1px !important;
					overflow: hidden;
					display: inline-block;
				}

				.desktop {
					@media (max-width: 979px) {
						display: none !important;
					}
				}

				.req {
					font-size: inherit;
					line-height: inherit;
					font-family: inherit;
					color: #FF6B00 !important;
					font-weight: bold !important;
				}

				.react-select-container input {
					box-shadow: none !important;
				}

				.mobile {
					@media (min-width: 980px) {
						display: none !important;
					}
				}

				.notmobilephone {
					@media (max-width: 659px) {
						display: none !important;
					}
				}

				.mobilephone {
					@media (min-width: 660px) {
						display: none !important;
					}
				}

				@media (max-width: 979px) {
					min-height: calc(100vh - 100px);
					/* margin-top: 100px; */
				}

				.test {
					border: 1px solid red;
				}

				.filler {
					flex-grow: 1;
				}

				a {
					text-decoration: none;
					color: ${props => props.theme.darkBlue};

					&:hover {
						color: ${props => props.theme.lightBlue};
					}

					&.active {
						color: ${props => props.theme.lightBlue};
					}
				}

				p {
					font-family: ${props => props.theme.pt};
					font-size: 16px;
					line-height: 21px;
					color: #333;

					b {
						font-family: inherit;
						font-size: inherit;
						line-height: inherit;
						color: inherit;
						font-weight: bold;
					}
				}

				.lineDelimiter {
					height: 1px;
					background-color: ${props => props.theme.lightGray};
					margin: 20px 0;
				}

				h1, h2, h3, h4, h5, h6 {
					color: ${props => props.theme.darkBlue};
					font-weight: 500;
					margin-bottom: 10px;

					&.orange {
						color: #FF6B00 !important;
					}

					&.margin5 {
						margin-bottom: 5px !important;
					}
				}

				h1 {
					font-size: 24px;
					line-height: 28px;
					margin-bottom: 20px;

					@media (max-width: 979px) {
						margin-bottom: 10px;
					}

					b {
						color: #00C0FF;
					}
				}

				h2 {
					font-size: 20px;
					line-height: 24px;
				}

				h3 {
					font-size: 16px;
					line-height: 19px;
				}

				h4 {
					font-size: 14px;
					line-height: 17px;
				}

				h5 {
					font-size: 12px;
					line-height: 14px;
				}

				h6 {
					font-size: 16px;
					line-height: 19px;
				}

				.whiteLine {
					height: 0;
					width: 100%;
					border-top: 1px solid white;
				}

				.container {
					width: calc(100vw - 80px);
					max-width: 1440px;

					@media (max-width: 979px) {
						width: calc(100vw - 40px);
					}
				}

				.blueContainer, .lightBlueContainer {
					color: white;
					max-width: 100vw;
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;

					&.blueContainer {
						background-color: ${props => props.theme.darkBlue};
					}

					&.lightBlueContainer {
						background-color: #D3EFFF;
					}

					> div {
						width: calc(100vw - 80px);
						max-width: 1440px;
						/* display: flex; */
						/* flex-direction: column; */
						/* align-items: center; */

						@media (max-width: 980px) {
							width: calc(100vw - 40px);
						}
					}
				}

				.button {
					cursor: pointer;
					min-height: 30px;
					font-size: 14px;
					line-height: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					border-radius: 5px;
					outline: none !important;
					padding: 0 20px;
					gap: 5px;

					&.noWrap {
						white-space: nowrap;
					}

					&.big {
						min-height: 40px;
					}

					&.height30 {
						font-size: 14px;
						line-height: 17px;
						min-height: 30px;
					}

					&.white {
						color: ${props => props.theme.darkBlue};
						border: 2px solid ${props => props.theme.darkBlue};
						background-color: white;

						&:hover {
							color: white;
							background-color: ${props => props.theme.darkBlue};
						}
					}

					&.blue {
						border: 2px solid ${props => props.theme.darkBlue};
						color: white;
						background-color: ${props => props.theme.darkBlue};

						&:hover {
							color: ${props => props.theme.darkBlue};
							background-color: white;
						}
					}

					&.transparent {
						border: 2px solid ${props => props.theme.darkBlue};
						color: ${props => props.theme.darkBlue};
						background-color: transparent;

						&:hover {
							color: white;
							background-color: ${props => props.theme.darkBlue};
						}
					}

					&.lightBlue {
						color: ${props => props.theme.darkBlue};
						background-color: #D3EFFF;

						&:hover {
							color: ${props => props.theme.lightBlue};
							/* background-color: white; */
						}
					}

					&.red {
						border: 2px solid #A2001D;
						background-color: #A2001D;
						color: white;
					}

					&.grad {
						background-color: #0270CB;
						background-image: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
						background-size: 100% calc(100% + 10px);
						background-position: 0 0;
						transition: background-position .2s ease-in-out;
						color: white;
						border: none;

						&:hover {
							background-position: 0 -10px;
						}
					}

					&.orange {
						background-color: #0270CB;
						background-image: linear-gradient(180deg, #FEB446 0%, #FF6B00 100%);
						background-size: 100% calc(100% + 10px);
						background-position: 0 0;
						transition: background-position .2s ease-in-out;
						color: white;
						border: none;

						&:hover {
							background-position: 0 -10px;
						}
					}

					&.disabled {
						background-color: #D1D1D1 !important;
						background-image: none !important;
					}
				}

				.marginLeft20 { margin-left: 20px; }

				.buttonAsLink {
					font-family: ${props => props.theme.pt};
					font-size: 16px;
					line-height: 21px;
					color: ${props => props.theme.darkBlue};
					transition: color .2s ease-in-out;
					display: flex;
					flex-direction: row;
					align-items: center;
					cursor: pointer;

					&.bold {
						font-weight: 700;
					}

					&.orange {
						color: #FF6B00 !important;
					}

					svg * {
						color: ${props => props.theme.darkBlue};
						transition: color .2s ease-in-out; 
					}
					
					path[fill] { 
						fill: ${props => props.theme.darkBlue};
						transition: fill .2s ease-in-out; 
					}
					
					path[stroke] {
						stroke: ${props => props.theme.darkBlue};
						transition: stroke .2s ease-in-out; 
					}
					
					&:hover, &.active { 
						color: ${props => props.theme.lightBlue};
						
						> svg {
							* {
								color: ${props => props.theme.lightBlue};
							}

							path[fill] { fill: ${props => props.theme.lightBlue}; } 
							path[stroke] { stroke: ${props => props.theme.lightBlue}; }
						}
					}
					
					img, svg {
						//width: 20px;
						//height: 20px;
						flex-grow: 0;
						flex-shrink: 0;
						object-fit: cover;
						object-position: center;
						margin-right: 5px;
					}
				}

				input[type="text"]:not(.react-select__input):not(.form__input), input[type="password"], input:not([type]) {
					padding: 0 10px;
					height: 30px;
					border-radius: 2px;
					width: 100%;
					outline: none !important;
					color: ${props => props.theme.darkBlue};
					font-family: ${props => props.theme.pt};
					font-size: 16px;
					line-height: 21px;
					border: 1px solid ${props => props.theme.inputBorderColor};
					background-color: white;
					transition: box-shadow .2s ease-in-out;
					box-shadow: 0 0 0 rgba(33, 33, 33, .3);

					&.big {
						height: 40px;
					}

					&::placeholder {
						color: ${props => props.theme.placeholderColor};
					}

					&:focus:not([readonly]) {
						box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
					}
				}

				input.inputCheckbox {
					display: none;

					& + label {
						display: flex;
						flex-direction: row;
						align-items: center;

						&:before {
							content: '';
							display: flex;
							width: 20px;
							height: 20px;
							margin-right: 5px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
							background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%230270CB'/%3E%3C/svg%3E%0A");
							flex: 0 0 20px;
						}
					}

					&:checked + label:before {
						background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%230270CB'/%3E%3Cpath d='M6.00037 9L9.00037 12L14.0004 7' stroke='%230270CB' stroke-width='2'/%3E%3C/svg%3E%0A");
					}
				}

				textarea {
					padding: 0 10px;
					width: 100%;
					outline: none !important;
					color: ${props => props.theme.darkBlue};
					font-family: ${props => props.theme.pt};
					font-size: 16px;
					line-height: 21px;
					border: 1px solid ${props => props.theme.inputBorderColor};
					border-radius: 2px;
					background-color: white;
					transition: box-shadow .2s ease-in-out;
					box-shadow: 0 0 0 rgba(33, 33, 33, .3);
					resize: none;

					&::placeholder {
						color: ${props => props.theme.placeholderColor};
					}

					&:focus:not([readonly]) {
						box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
					}
				}
			}
		}
	}
`;
