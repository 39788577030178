import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import arrow from 'images/whiteArrowLeft.svg';
import arrowBlue from 'images/arrowBlue.svg';
import close from 'images/close.svg';
import {FullWidth, Pictogram} from './Media';
import {Colors} from 'constants';

const Previews = styled.div`
	${FullWidth}
	margin: 0 auto 40px auto;
	padding-top: 20px;
	@media (min-width: 1280px) {
		padding-top: 40px; 
	}
	border-top: 1px solid #034781;
	border-bottom: 1px solid #034781;
	
	display: none;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (min-width: 980px) { display: flex; }	

	div {
		${Pictogram}
		margin-bottom: 40px;
		margin-left: 20px;
		margin-right: 20px;
		@media (max-width: 1280px) {
			margin-bottom: 20px;
			margin-left: 10px;
			margin-right: 10px;
		}
		position: relative;
		cursor: pointer;
		
		&:nth-child(6n+1) {
			margin-left: 0;
		}
		
		&:nth-child(6n) {
			margin-right: 0;
		}
		
		img {
			width: 100%;
			height: 100%;
			z-index: 10;
		}
		
		&:before {
			content: '';
			position: absolute;
			z-index: 11;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(3, 71, 129, 0.6);
			opacity: 0;
			visibility: hidden;
			transition: all .2s ease-in-out;
		}
		
		&:hover:before {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Container = styled.div`
	@media (max-width: 539px) { width: 100% !important; }

	@media (max-width: 979px) {
		border-top: 1px solid ${Colors.blue};
		border-bottom: 1px solid ${Colors.blue};
		padding: 20px 0;
		margin-bottom: 20px;
		position: relative;
	}
	
	@media (min-width: 980px) {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1001;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		background: rgba(3, 71, 129, 0.8);

		${props => props.$isShow && `opacity: 1; visibility: visible;`}
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const Prev = styled.div`
	width: 18px;
	height: 30px;
	@media (min-width: 980px) { background-image: url(${arrow}); }
	@media (max-width: 979px) { background-image: url(${arrowBlue}); }
	background-position: center;
	background-size: cover;
	//margin-left: 40px;
	//@media (max-width: 1280px) { margin-left: 20px; };
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	position: absolute;
	left: 10px;
	@media (min-width: 660px) { left: 20px; }
	z-index: 20;
	
	${props => props.$isHidden && `opacity: 0; visibility: hidden;`}
`;

const Next = styled.div`
	width: 18px;
	height: 30px;
	@media (min-width: 980px) { background-image: url(${arrow}); }
	@media (max-width: 979px) { background-image: url(${arrowBlue}); }
	background-position: center;
	background-size: cover;
	transform: rotate(-180deg);
	cursor: pointer;
	//margin-right: 40px;
	//@media (max-width: 1280px) { margin-right: 20px; };
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	position: absolute;
	right: 10px;
	@media (min-width: 660px) { right: 20px; }
	z-index: 20;
	
	${props => props.$isHidden && `opacity: 0; visibility: hidden;`}
`;

const Close = styled.div`
	position: absolute;
	z-index: 1002;
	width: 30px;
	height: 30px;
	background-image: url(${close});
	background-position: center;
	background-size: cover;
	top: 40px;
	right: 40px;
	cursor: pointer;
	@media (max-width: 1280px) {
		top: 20px;
		right: 20px;
	}
	
	@media (max-width: 979px) { display: none; }
`;

const SlidesContainer = styled.div`
	overflow: hidden;
	height: calc(100% - 220px);
	@media (max-width: 1280px) { height: calc(100% - 140px); }

	
	@media (max-width: 539px) {
		width: 280px !important;
	}
`;

const Slides = styled.div`
	display: flex;
	flex-direction: row;
	transition: all .2s ease-in-out;
	
	transform: translate(${props => -1 * props.$width * props.$index}px);
	@media (min-width: 540px) { transform: translate(${props => -460 * props.$index}px); }
	@media (min-width: 660px) { transform: translate(${props => -620 * props.$index}px); }
	@media (min-width: 980px) { transform: translate(${props => -940 * props.$index}px); }
	@media (min-width: 1280px) { transform: translate(${props => -1160 * props.$index}px); }
	@media (min-width: 1600px) { transform: translate(${props => -1400 * props.$index}px); }

	align-items: center;
	height: 100% !important;
`;

const Slide = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	
	img {
		max-width: 100%;
		max-height: 100%;
	}
	
	@media (max-width: 539px) {
		width: 280px !important;
	}
`;

export const Gallery = ({items = [], show = false}) => {
	const [ isShow, setIsShow ] = useState(show);
	const [ current, setCurrent ] = useState(0);
	const SlidesContainerRef = useRef();
	const [ width, setWidth ] = useState(0);

	const handlePrev = () => {
		if (current > 0) {
			setCurrent(current - 1);
		}
	};

	const handleNext = () => {
		if (current < items.length - 1) {
			setCurrent(current + 1);
		}
	};

	const handleClose = () => setIsShow(false);

	const handleShow = i => {
		setCurrent(parseInt(i));
		setTimeout(() => setIsShow(true), 201);
	};

	useEffect(() => {
		setWidth(SlidesContainerRef.current.getBoundingClientRect().width);
	}, []);

	return (
		<>
			<Previews>
				{items.map((i, key) => <div key={key} onClick={() => handleShow(key)}><img src={`/images/news/${i.thumb}`} alt="" /></div>)}
			</Previews>

			<Container $isShow={isShow} className=''>
				<Prev onClick={handlePrev} $isHidden={current === 0} />

				<SlidesContainer className='col-1 full-320 center-margin' ref={SlidesContainerRef}>
					<Slides $index={current} $width={width}>
						{items.map((i, key) => <Slide className='col-1 full-320' key={key}><img src={`/images/news/${i.image}`} alt="" /></Slide>)}
					</Slides>
				</SlidesContainer>

				<Next onClick={handleNext} $isHidden={current === (items.length - 1)} />

				<Close onClick={handleClose} />
			</Container>
		</>
	);
};