import React, {useEffect} from 'react';
import styled from 'styled-components';
import pictoTech from 'images/picto_tech.svg';
import schemeHotels from 'images/scheme-integration-hotels.svg';
import schemeHotelsEn from 'images/hotels_scheme_eng.svg';
import schemeAgents from 'images/scheme-integration-agents.svg';
import schemeAgentsEn from 'images/agents_scheme_eng.svg';
import pictoDoc2 from 'images/picto_doc_2.svg';
import {Link, useParams} from 'react-router-dom';

import bnovoScr from 'images/systems/bnovo-scr.jpg';
import bookingliteScr from 'images/systems/bookinglite.jpg';
import bookingliteScrEn from 'images/systems/bookinglite_eng.png';
import corteosScr from 'images/systems/corteos-scr.jpg';
import frontdesk24Scr from 'images/systems/frontdesk24-scr.jpg';
import hotelInstinctScr from 'images/systems/hotelInstinct-scr.jpg';
import hotelmsScr from 'images/systems/hotelms-scr.jpg';
import konturScr from 'images/systems/kontur-scr.jpg';
import konturScrEn from 'images/systems/kontur_eng.png';
import nextaScr from 'images/systems/nexta-scr.jpg';
import travelclickScr from 'images/systems/travelclick-scr.jpg';
import travellineScr from 'images/systems/travelline-scr.jpg';
import travellineScrEn from 'images/systems/travel_eng.png';
import wubookScr from 'images/systems/wubook-scr.jpg';
import wubookScrEn from 'images/systems/wubook_eng.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const MyButton = styled(Link)`
	@media (max-width: 539px) {
		flex-direction: column !important;
		align-items: center !important;
		text-align: center;
		
		img { margin-bottom: 10px }
	}
`;


const Tech = () => {
	const { t } = useTranslation();
	const params = useParams();
	const id = params?.id;
	
	useEffect(() => window.scrollTo(0, 0), [id]);
	
	const items = [
		{
			title: t('Менеджеры каналов'),
			items: [
				{
					title: 'Travelline',
					body: t('TL: Channel Manager — первый менеджер каналов, разработанный в России с учетом всего своеобразия требований российских отельеров и предоставляющий клиентам бесплатную русскоязычную поддержку. Система работает с 91 каналом — российскими и международными OTA, туроператорами, GDS, отличаясь неизменно высокой скоростью доставки броней и обновлений. С 2020 года компания TravelLine находится в статусе Booking Premier Partner, как партнер с широким спектром услуг и высоким качеством соединения.'),
					img: i18n.language === 'ru' ? travellineScr : travellineScrEn
				},
				{
					title: 'TravelClick',
					body: t('Надежный партнер гостиничного бизнеса с более чем 30-летним опытом работы в отрасли, предлагает инновационные облачные и Data Driven решения, помогающие отелям максимизировать свой доход. Более 25000 клиентов используют TravelClick, чтобы найти, привлечь и удержать гостей с помощью взаимосвязанного набора решений: бизнес-аналитики, системы резервирования и бронирования, различных веб- и медиа-каналов, менеджмента постояльцев. Отели дополнительно выигрывают от использования более чем 600 партнерских программ, ориентированных на сферу путешествий. TravelClick работает в 176 странах, включая местных экспертов в 39 из них. 14 офисов компании расположены по всему миру: Нью-Йорк, Атланта, Барселона, Бухарест, Чикаго, Даллас, Дубай, Гонконг, Мельбурн, Орландо, Оттава, Париж, Шанхай и Сингапур.'),
					img: travelclickScr
				},
				{
					title: 'WuBook',
					body: t('Один из лидеров в разработке программного обеспечения для управления номерным фондом. Систему отличают надежность, производительность и высокая скорость работы, в сочетании с простотой и удобством использования. Предлагаемые продукты постоянно улучшаются и развиваются, качество работы сервиса признано важнейшими игроками на рынке, определяющими Wubook как привилегированного партнера. Среди них: Booking.com, Airbnb, Google, Expedia и многие другие.'),
					img: i18n.language === 'ru' ? wubookScr : wubookScrEn
				},
				{
					title: 'Bnovo',
					body: t('Channel Manager для управления онлайн-продажами на более чем 20-ти каналах бронирования. Позволяет одновременно управлять ценами, наличием номеров, ограничениями и контентом в различных каналах онлайн-бронирования. Отдельное внимание уделяется проблемам безопасности — хранение данных карт, полученных от систем бронирования, осуществляется в соответствии со стандартами безопасности PCI DSS, персональные данные гостей хранятся в соответствии с 152-ФЗ.'),
					img: bnovoScr
				},
				{
					title: t('Контур.Отель'),
					body: t('Сервис позволяет управлять номерным фондом, получать брони с сайта отеля и площадок бронирования, а также представлять сведения в МВД о гостях через интернет. Менеджер каналов бронирования бесшовно интегрирован с системами управления отелями и площадками бронирования. Напрямую грузит данные по броням в системы управления отелями с площадок, с которыми заключены соглашения, передает информацию о стоимости номеров, количестве и категориях доступных для продажи номеров на площадки бронирования.'),
					img: i18n.language === 'ru' ? konturScr : konturScrEn
				},
			]
		},
		{
			title: t('Облачные PMS'),
			items: [
				{
					title: 'OtelMS',
					body: t('Компания OtelMS, созданная в Ялте в 2013 году — разработчик популярной системы управления HMS OtelMS и профессиональных гостиничных сайтов. Успешный опыт Компании в управлении гостиничными объектами и понимание нужд отельеров, обеспечил создание эффективной облачной системы, позволяющей управлять своим бизнесом удаленно. HMS OtelMS — это система управления отелем, Channel Manager, модуль онлайн бронирования, модуль подачи миграционных отчетов, оператор интернет-эквайринга. Системой успешно пользуются свыше 5 000 больших и малых гостиничных объектов по всему миру.'),
					img: hotelmsScr
				},
				{
					title: 'Frontdesk24',
					body: t('Российская система управления отелем полного цикла. Единая облачная платформа включает в себя, PMS, онлайн-бронирование на сайте гостиницы, Channel Manager, позволяющий получать бронирования с 30 основных площадок, Revenue management, интеграции с электронными замками, кассами, терминалами self check-in, CRM, системами почтовой и sms рассылки, а также, развитую систему отчетности. Систему использует более 1000 отелей в России и странах СНГ.'),
					img: frontdesk24Scr
				},
				{
					title: 'HotelInstinct',
					body: t('Единая система управления для отелей, хостелов и апартаментов. Берет на себя всю рутинную работу, улучшает и ускоряет все этапы работы средств размещения: от создания брони до подготовки документов и правильных отчетов. Обладает понятным и удобным интерфейсом, ведет учет финансов и формирует своевременные отчеты. Имеет широкие возможности по интеграции с внешними системами — смс-рассылкой, фискальными регистраторами, платежными системами, дает возможность импорта различных видов внешних данных.'),
					img: hotelInstinctScr
				},
				{
					title: 'BookingLite',
					body: t('BookingLite – программа для управления отелем, гостиницей или апартаментами со встроенным Менеджером каналов, который синхронизирует отель с системами бронирования. Неограниченное количество номеров и сотрудников, возможность СМС и Email уведомлений как работников так и гостей, модуль бронирования для сайта, решения для сети отелей и многие другие возможности.'),
					img: i18n.language === 'ru' ? bookingliteScr : bookingliteScrEn
				},
			]
		},
		{
			title: t('Системы для турагентов'),
			items: [
				{
					title: 'Corteos',
					body: t('Меняет подход к автоматизации процессов в деловом туризме. Открытая платформа с собственным маркетплейсом, объединяет всех участников индустрии в единую экосистему, где каждый может создавать и распространять решения собственными силами. Возможность распределенного существования системы позволяет всему сообществу противостоять различным внешним угрозам и сохранять собственную уникальность.'),
					img: corteosScr
				},
				{
					title: 'Nexta',
					body: t('Онлайн-система бронирования, предназначенная для турагентств и их клиентов с возможностью работы по White Label. Nexta интегрирована с крупными поставщиками тревел-услуг, умеет применять тревел-политику и 3D, согласовывать поездки, управлять сборами, формировать отчетность, обмениваться данными с внешними программами, адаптируясь под бизнес-процессы клиента. Вместе с системой оперучета Finex автоматизирует 2-сторонний обмен данными между поставщиками услуг и бэк-офисом компании.'),
					img: nextaScr
				},
			]
		},
	];

	if (id === 'where') {
		return (
			<>
				<div className='text-block pt-sans padding-80 font-20 color-grey'>
					{t('Возможность съэкономить время и бюджет, выбрав уже готовое техническое решение.')}
				</div>

				<div className='lightblue-container flex column align-center padding-80'>
					{items.map((i, key) =>
						<React.Fragment key={key}>
							<p className='text-uppercase color-blue text-center font-bold font-42 margin-bottom-60'>{i.title}</p>
							<div className='divider col-1 margin-bottom-40' />

							{i.items.map((j, key) =>
								<div className={classNames('item', 'col-1', {'margin-bottom-40': key + 1 < i.items.length}, {'margin-bottom-80': key + 1 === i.items.length}, 'flex', 'row', 'justify-between')} key={key}>
									{key % 2 === 0 && <img src={j.img} alt="" className='col-3' />}
									<div>
										<p className='color-blue font-34 margin-bottom-20'>{j.title}</p>
										<img src={j.img} alt="" className='col-1 full-320' />
										<p className='pt-sans color-grey font-20 margin-bottom-20'>{j.body}</p>
									</div>
									{key % 2 !== 0 && <img src={j.img} alt="" className='col-3' />}
								</div>
							)}
						</React.Fragment>
					)}
				</div>

				<div className='block-fullwidth bg-blue padding-80'>
					<p className='col-1 text-center color-white font-42 font-bold'>
						{t('Хотите подключить свой сервис?')} {t('Пишите на')} <a href="mailto:ok@acase.ru" className='text-underline'>ok@acase.ru</a>
					</p>
				</div>

				<div className='block-fullwidth bg-blue'>
					<div className='divider col-1 bg-white' />
				</div>
			</>
		);
	}

	if (id === 'for_agents') {
		return (
			<>
				<div className='text-block pt-sans padding-80 font-20 color-grey'>
					{t('Возможность продавать весь ассортимент гостиниц «АКАДЕМ-ОНЛАЙН» прямо на своем сайте и в своей корпоративной системе бронирования.')}
				</div>

				<div className='divider col-1 margin-bottom-80' />

				<a href={schemeAgents} target="_blank" className='text-block margin-bottom-80' rel="noreferrer">
					<img src={i18n.language === 'ru' ? schemeAgents : schemeAgentsEn} alt='' className='block-fullwidth' />
				</a>

				<MyButton to='/agent' className='button col-1 margin-bottom-80'>
					<img src={pictoDoc2} alt='' />
					{t('Скачать соглашение об XML-соединении')}
				</MyButton>

				<div className='block-fullwidth bg-blue padding-80 margin-bottom-80'>
					<p className='col-1 text-center color-white font-42 font-bold'>
						{t('По вопросам интеграции пишите на')} <a href="mailto:support@acase.ru" className='text-underline'>support@acase.ru</a>
					</p>
				</div>

				<MyButton to='/techs/where' className='button col-1 margin-bottom-80'>
					<img src={pictoTech} alt="" />
					{t('Где мы представлены')}
				</MyButton>

				<div className='block-fullwidth bg-blue padding-80'>
					<p className='col-1 text-center color-white font-42 font-bold'>
						{t('Хотите подключить свой модуль?')} {t('Пишите на')} <a href="mailto:ok@acase.ru" className='text-underline'>ok@acase.ru</a>
					</p>
				</div>

				<div className='block-fullwidth bg-blue'>
					<div className='divider col-1 bg-white' />
				</div>
			</>
		);
	}

	return (
		<>
			<div className='text-block pt-sans padding-80 font-20 color-grey'>
				{t('Возможность управлять ценами, наличием мест и получать бронирования прямо в системе управления отелем или в менеджере каналов.')}
			</div>

			<div className='divider col-1 margin-bottom-80' />

			<a href={schemeHotels} target="_blank" className='text-block margin-bottom-80' rel="noreferrer">
				<img src={i18n.language === 'ru' ? schemeHotels : schemeHotelsEn} className='block-fullwidth' alt='' />
			</a>

			<div className='block-fullwidth bg-blue padding-80'>
				<p className='col-1 text-center color-white font-42 font-bold'>
					{t('Для подключения Вашего отеля пишите на')} <a href="mailto:ok@acase.ru" className='text-underline'>ok@acase.ru</a>
				</p>
			</div>

			<div className='lightblue-container flex column align-center padding-80'>
				{items.map((i, key) =>
					<React.Fragment key={key}>
						<p className='text-uppercase color-blue text-center font-bold font-42 margin-bottom-60'>{i.title}</p>
						<div className='divider col-1 margin-bottom-40' />

						{i.items.map((j, key) =>
							<div className={classNames('item', 'col-1', {'margin-bottom-40': key + 1 < i.items.length}, {'margin-bottom-80': key + 1 === i.items.length}, 'flex', 'row', 'justify-between')} key={key}>
								{key % 2 === 0 && <img src={j.img} alt="" className='col-3' />}
								<div>
									<p className='color-blue font-34 margin-bottom-20'>{j.title}</p>
									<img src={j.img} alt="" className='col-1 full-320' />
									<p className='pt-sans color-grey font-20 margin-bottom-20'>{j.body}</p>
								</div>
								{key % 2 !== 0 && <img src={j.img} alt="" className='col-3' />}
							</div>
						)}
					</React.Fragment>
				)}
			</div>

			<div className='block-fullwidth bg-blue padding-80'>
				<p className='col-1 text-center color-white font-42 font-bold'>
					{t('Хотите подключить свой Channel manager или облачную PMS?')} {t('Пишите на')} <a href="mailto:ok@acase.ru" className='text-underline'>ok@acase.ru</a>
				</p>
			</div>

			<div className='block-fullwidth bg-blue'>
				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
};

export default Tech;