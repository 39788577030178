import React, {useEffect} from 'react';
import styled from 'styled-components';
import close from 'images/close.svg';
import pictoHotel from 'images/picto_add_hotel.svg';
import pictoAgent from 'images/picto_agent.svg';
import pictoInner from 'images/picto_inner.svg';
import pictoPrivate from 'images/picto_private.svg';
import { useTranslation } from 'react-i18next';

const LoginContainer = styled.div`
	background-color: rgba(3, 71, 129, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1004;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	${props => props.$isShow && `opacity: 1; visibility: visible;`}
	
	padding-top: 40px;
	@media (min-width: 660px) { padding-top: 120px; }
	@media (min-width: 1280px) { padding-top: 140px; }
`;

const Close = styled.div`
	position: absolute;
	z-index: 1002;
	width: 30px;
	height: 30px;
	background-image: url(${close});
	background-position: center;
	background-size: cover;
	top: 40px;
	right: 40px;
	cursor: pointer;
	@media (max-width: 1280px) {
		top: 20px;
		right: 20px;
	}
`;

const LoginWindow = styled.div`
	background-color: white;
	
	div {
		display: flex;
		margin: 0 20px;
		position: relative;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		@media (min-width: 660px) {
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
		}

		@media (min-width: 980px) {
			flex-wrap: nowrap;
		}

		a {
			flex-shrink: 0;
			flex-grow: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 20px;
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
			text-transform: uppercase;
			color: #004685;
			width: calc(100% - 40px);

			img {
				width: 80px;
				height: 80px;
			}

			@media (min-width: 660px) {
				width: calc((100% - 40px) / 2) !important;
			}

			@media (min-width: 980px) {
				width: calc((100% - 60px) / 4) !important;

				img {
					width: 160px;
					height: 160px;
				}
			}
		}
	}
`;

export const Login = ({isShow = false, setIsShow = () => null}) => {
	const { t } = useTranslation();

	const handleClose = () => {
		setIsShow(false);
	};

	useEffect(() => setIsShow(false), []);

	return (
		<LoginContainer $isShow={isShow}>
			<Close onClick={handleClose} />

			<LoginWindow className='col-1 padding-40'>
				<p className='color-blue font-42 text-uppercase text-center margin-bottom-40'>{t('Вход в личный кабинет')}</p>

				<div>
					<a href='https://acase.ru/login' target='_blank' rel="noreferrer">
						<img src={pictoPrivate} alt='' className='width-picto margin-bottom-20' />
						{t('Для частных лиц')}
					</a>

					<a href='https://b2b.acase.ru' target='_blank' rel="noreferrer">
						<img src={pictoAgent} alt='' className='width-picto margin-bottom-20' />
						{t('Для агентов')}
					</a>

					<a href='https://academservice.ru/online/ru-login.jsp?IsCompany=4' target='_blank' rel="noreferrer">
						<img src={pictoHotel} alt='' className='width-picto margin-bottom-20' />
						{t('Для отелей')}
					</a>

					<a href='https://academservice.ru/online/ru-login.jsp?IsCompany=3' target='_blank' rel="noreferrer">
						<img src={pictoInner} alt='' className='width-picto margin-bottom-20' />
						{t('Для сотрудников')}
					</a>
				</div>
			</LoginWindow>
		</LoginContainer>
	);
};