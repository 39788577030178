import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import milanBg from 'images/milan.jpg';
import servicesBg from 'images/services_bg.jpg';
import addHotelBg from 'images/add_hotel_bg.jpg';
import handCard from 'images/hand-card.jpg';
import ipadBg from 'images/ipad.jpg';
import handshakeBg from 'images/handshake.jpg';
import coffeeBg from 'images/coffee.jpg';
import penBg from 'images/pen.jpg';
import hotelBell from 'images/hotel-bell.jpg';
import blankBg from 'images/blank.jpg';
import {observer} from 'mobx-react-lite';
import classnames from 'classnames';
import {useNewsStore} from 'store/useNewsStore';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Container = styled.div`
	position: relative;
	background-image: url(${props => props.$bg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 40px 0;
	@media (min-width: 980px) { padding: 120px 0 160px 0; }
	
	&:before {
		content: '';
		background-color: rgba(3, 71, 129, 0.6);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}

	h1, p {
		z-index: 20;
	}
	
	> div {
		height: 40px;
		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		line-height: 17px;
		color: #034781;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 11;
		@media (max-width: 979px) { display: none; }
		
		a {
			text-decoration: none;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 17px;
			color: #034781;
			margin: 0 5px;
			
			&:last-child {
				color: #0270CB;
			}
			
			&:hover {
				color: #0270CB;
			}
		}
	}
`;

export const Banner = observer(props => {
	const location = useLocation();
	const { t } = useTranslation();
	const { items } = useNewsStore();
	const locale = i18n.language;

	const breadcrumbs = location.pathname.split('/').map(i => {
		switch (i) {
		case '': return { link: '/', label: t('Главная') };
		case 'about': return { link: '/about', label: t('О нас'), cover: milanBg };
		case 'services': return { link: '/services', label: t('Услуги'), cover: servicesBg };
		case 'online_booking': return { link: '/service/online_booking', label: t('Система онлайн-бронирования'), cover: addHotelBg };
		case 'gds_ads': return { link: '/service/gds_ads', label: t('GDS/ADS провайдинг'), cover: addHotelBg };
		case 'foreign_hotels': return { link: '/service/foreign_hotels', label: t('Зарубежные отели'), cover: addHotelBg };
		case 'agent': return { link: '/agent', label: t('Стать агентом'), cover: handCard };
		case 'techs': return { link: '/techs', label: t('Технологии'), cover: ipadBg };
		case 'for_hotels': return { link: '/tech/for_hotels', label: t('Интеграция для отелей'), cover: ipadBg };
		case 'for_agents': return { link: '/tech/for_agents', label: t('Интеграция для агентов'), cover: ipadBg };
		case 'where': return { link: '/tech/where', label: t('Где мы представлены'), cover: ipadBg };
		case 'contacts': return { link: '/tech/contacts', label: t('Контакты'), cover: handshakeBg };
		case 'news': return { link: '/news', label: t('Новости'), cover: coffeeBg };
		case 'recommends': return { link: '/recommends', label: t('Отзывы'), cover: penBg };
		case 'add_hotel': return { link: '/add_hotel', label: t('Добавить отель'), cover: addHotelBg };
		case 'legaldocs': return { link: '/legaldocs', label: t('Правовые документы'), cover: penBg };

		case 'countries': return { link: '/countries', label: t('Отели'), cover: hotelBell };
		case 'country': return { link: '/countries', label: t('Отели'), cover: hotelBell };
		case 'city': return { link: '/countries', label: t('Отели'), cover: hotelBell };
		case 'hotel': return { link: '/countries', label: t('Отели'), cover: hotelBell };

		case 'error404': return { link: '/error404', label: t('404 — Страница не найдена'), cover: blankBg };
		default: return { link: '/', label: '? ? ?' };
		}
	});

	if (breadcrumbs[1].link === '/news' && breadcrumbs.length === 3) {
		const item = items.find(i => i.id === parseInt(location.pathname.split('/')[2]));
		breadcrumbs[2] = {
			link: '/news/' + location.pathname.split('/')[2],
			label: (locale !== 'ru' && !!item?.enTitle) ? item?.enTitle : item?.title,
			date: item?.date ?? '',
			cover: coffeeBg
		};
	}

	if (location.pathname.split('/')[1] === 'country') {
		const { countryStore } = props;

		breadcrumbs[2] = {
			link: countryStore.country.Code === undefined ? '' : `/country/${countryStore.country.Code}`,
			label: countryStore.country.Code === undefined ? '' : countryStore.country.Name,
			cover: hotelBell
		};
	}

	if (location.pathname.split('/')[1] === 'city') {
		const { cityStore } = props;

		breadcrumbs[2] = {
			link: cityStore.country.Code === undefined ? '' : `/country/${cityStore.country.Code}`,
			label: cityStore.country.Code === undefined ? '' : cityStore.country.Name,
			cover: hotelBell
		};

		breadcrumbs[3] = {
			link: cityStore.city.Code === undefined ? '' : `/city/${cityStore.country.Code}-${cityStore.city.Code}`,
			label: cityStore.city.Code === undefined ? '' : cityStore.city.Name,
			cover: hotelBell
		};
	}

	if (location.pathname.split('/')[1] === 'hotel') {
		const { hotelStore } = props;

		breadcrumbs[2] = {
			link: hotelStore.hotel.Code === undefined ? '' : `/country/${hotelStore.hotel.Country.Code}`,
			label: hotelStore.hotel.Code === undefined ? '' : hotelStore.hotel.Country.Name,
			cover: hotelBell
		};

		breadcrumbs[3] = {
			link: hotelStore.hotel.Code === undefined ? '' : `/city/${hotelStore.hotel.Country.Code}-${hotelStore.hotel.City.Code}`,
			label: hotelStore.hotel.Code === undefined ? '' : hotelStore.hotel.City.Name,
			cover: hotelBell
		};

		breadcrumbs[4] = {
			link: hotelStore.hotel.Code === undefined ? '' : `/hotel/${hotelStore.hotel.Ref}-${hotelStore.hotel.Code}`,
			label: hotelStore.hotel.Code === undefined ? '' : hotelStore.hotel.Name,
			cover: hotelBell
		};
	}

	return (
		<Container className='block-fullwidth flex column align-stretch' $bg={breadcrumbs.slice(-1)[0].cover}>
			{breadcrumbs.slice(-1)[0].date !== undefined && <p className='text-center font-16 color-white margin-bottom-40'>{breadcrumbs.slice(-1)[0].date}</p>}
			<h1 className={classnames('font-48', 'color-white', 'text-center', {'text-uppercase': !(breadcrumbs[1].link === '/news' && breadcrumbs.length === 3)})}>{breadcrumbs.slice(-1)[0].label === '' ? '\u00A0' : breadcrumbs.slice(-1)[0].label}</h1>

			{breadcrumbs.length > 0 && <div>
				{breadcrumbs.map((b, key) => <Link key={key} to={b.link}>{b.label}</Link>).reduce((prev, curr) => [prev, '>', curr])}
			</div>}
		</Container>
	);
});