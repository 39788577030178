import { useLocalObservable } from 'mobx-react-lite';
import {action} from 'mobx';
import i18n from 'i18next';

export const useSearchStore = mainStore => useLocalObservable(() => ({
	request(needle) {
		return {
			DestinationRequest: {
				UserType: 'buyer',
				BuyerId: 'RO1BAND',
				UserId: 'WWW',
				Password: '84765',
				Language: i18n.language,
				ProfitCentreCode: '',
				SUserCode: '',
				IsSyncOtgr: '',
				ActionSearch: {
					Parameters: {
						Text: needle,
						HighlightCssClass: 'Highlighter',
						NotSearchableCssClass: 'NotSearchableCss'
					}
				}
			}
		};
	},
	requestCountries: {
		CountryRequest: {
			UserType: 'buyer',
			BuyerId: 'RO1BAND',
			UserId: 'WWW',
			Password: '84765',
			Language: i18n.language,
			ProfitCentreCode: '',
			SUserCode: '',
			IsSyncOtgr: '',
			ActionPubliclist: {
				Parameters: {}
			}
		}
	},
	countries: [],
	setCountries: action(function(countries) { this.countries = countries; }),

	results: [],
	setResults: action(function(results) { this.results = results; }),

	fetch(needle) {
		if (this.countries.length === 0) {
			mainStore.ajax(this.requestCountries, data => this.setCountries(data.CountryResponse.CountryList.Country));
		}

		mainStore.ajax(this.request(needle), data =>
			this.setResults(data.DestinationResponse.DestinationList.Destination
				.map(i => ({
					...i,
					Name: i.Name.replace(/(<([^>]+)>)/gi, '')
				}))
			)
		);
	},
	clear() {
		this.setResults([]);
		this.setCountries([]);
	}
}));