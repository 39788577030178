import { useEffect, useState } from 'react';
import axios from 'axios';

const useMaintainance = () => {
	const [ data, setData ] = useState({
		isWorks: false,
		worksDate: '',
		worksTimeFrom: '',
		worksTimeTo: '',
	});

	useEffect(() => {
		axios.get('/', {})
			.then(data => setData({
				isWorks: parseInt(data?.headers?.isworks ?? 2),
				worksDate: data?.headers?.worksdate ?? '',
				worksTimeFrom: data?.headers?.workstimefrom ?? '',
				worksTimeTo: data?.headers?.workstimeto ?? '',
			}));
	}, []);

	return data;
};

export default useMaintainance;