import React, {useEffect} from 'react';
import styled from 'styled-components';
import marker from 'images/marker.svg';
import { observer } from 'mobx-react-lite';
import {Link, useParams} from 'react-router-dom';
import {Search} from 'components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	padding-top: 40px;
	padding-bottom: 20px;
	@media (min-width: 980px) {
		padding-top: 80px;
		padding-bottom: 40px;
	}

`;

const Cities = styled.div`

`;

/*
const Flag = styled.img`
	height: 40px;
	width: 60px;
	@media (min-width: 980px) { margin-right: 20px; }
	@media (max-width: 979px) { margin-bottom: 20px; }
	object-fit: cover;
`;
 */

const Marker = styled.img`
	width: 30px;
	height: 40px;
	margin-right: 15px;
`;

const Title = styled.p`
	flex-direction: column;
	@media (min-width: 980px) { flex-direction: row; }
`;

const Country = observer(props => {
	const { t } = useTranslation();
	const params = useParams();
	const id = parseInt(params?.id);
	const { countryStore } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
		countryStore.fetch(id);
		return countryStore.clear;
	}, []);

	return (
		<>
			<div className='text-block padding-80'>
				<Search {...props} />
			</div>

			<Container className='block-fullwidth bg-lightblue'>
				<Title className='margin-bottom-20 color-blue text-uppercase font-42 flex justify-center align-center text-center col-1'>
					{countryStore.country.Name}, {t('отели по городам')}
				</Title>

				{countryStore.regions.map(r =>
					<React.Fragment key={r.Code}>
						{r.Code !== 1 && <Title className='font-34 flex text-center align-center justify-center color-blue margin-bottom-20 col-1'>
							<Marker src={marker} />
							{r.Name}
						</Title>}

						<Cities className='margin-bottom-40 col-1 flex row wrap justify-between'>
							{countryStore.cities.filter(c => c.AdmUnit1.Code === r.Code).map((i, key) =>
								<Link to={`/city/${id}-${i.Code}`} key={key} className='col-3-2 font-20 flex row color-blue hover align-top margin-bottom-10'>
									{i.Name}
								</Link>
							)}
						</Cities>
					</React.Fragment>
				)}
			</Container>
		</>
	);
});

export default Country;