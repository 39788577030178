import React from 'react';
import styled from 'styled-components';
import {Filler, Lang} from './index';
import {NavLink} from 'react-router-dom';
import {Colors} from 'constants';
import { useTranslation } from 'react-i18next';

const HeaderDiv = styled.div`
	height: 40px;
	padding: 0 20px;
	@media (min-width: 1280px) { padding: 0 40px; }
	position: relative;
	
	@media (max-width: 979px) { display: none !important; }
	
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		@media (min-width: 1280px) {
			left: 40px;
			right: 40px;
		}
		height: 1px;
		background-color: #E6EDF3;
	}

	> ul {
		margin-right: 8px;
		
		> li {
			position: relative;
			
			&:hover {
				ul {
					opacity: 1;
					visibility: visible;
				}
			}
		
			a {
				margin: 0 3px;
				transition: all .2s ease-in-out;
				background-color: ${Colors.white};
				padding: 2px 5px;
				
				&.active, &:hover {
					color: ${Colors.lightblue};
					background-color: #D3EFFF;
				}
			}
			
			ul {
				position: absolute;
				top: 20px;
				z-index: 1002;
				background-color: ${Colors.white};
				border: 1px solid ${Colors.blue};
				width: 220px !important;
				padding: 10px;
				left: -50px;
				opacity: 0;
				visibility: hidden;
				transition: opacity .2s ease-in-out, visibility .2s ease-in-out;

				li {
					a {
						font-size: 14px;
						margin: 0;
						text-transform: none;
						padding: 5px 5px;
						display: block;
					}
					
					&:nth-child(n + 2) {
						margin-top: 10px;
					}
				}
			}
		}
	}
`;

export const Header = () => {
	const { t } = useTranslation();

	return (
		<HeaderDiv className='block-fullwidth flex row align-center justify-between text-uppercase'>
			<a href="https://acase.ru" className='color-blue font-14 font-bold' target="_blank" rel="noreferrer">{t('ГРУППА КОМПАНИЙ «АКАДЕМСЕРВИС»')}</a>

			<Filler />

			<ul className='flex row color-blue font-14'>
				<li><NavLink to="/about">{t('О нас')}</NavLink></li>
				<li><NavLink to="/news">{t('Новости')}</NavLink></li>
				<li><NavLink to="/contacts">{t('Контакты')}</NavLink></li>
				<li><NavLink to="/countries">{t('Отели')}</NavLink></li>
			</ul>

			<Lang />
		</HeaderDiv>
	);
};
