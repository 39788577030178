import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import { Advantages } from 'components';
import pictoAddHotel from 'images/picto_add_hotel.svg';
import pictoAgent from 'images/picto_agent.svg';
import pictoSystem from 'images/picto_system.svg';
import schemeAdsGds from 'images/scheme-gds-ads.svg';
import schemeAdsGdsEn from 'images/GDS-ADS_scheme_eng.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Buttons = styled.div`
	flex-wrap: wrap;
	
	a {
		border: 2px solid #034781;
		border-radius: 5px;
		
		@media (min-width: 540px) and (max-width: 979px) {
			width: 300px !important;
			height: 300px !important;
		}
		
		&:nth-child(n+2) {
			@media (min-width: 660px) { margin-left: 20px; }
			@media (max-width: 659px) { margin-top: 20px; }
			@media (min-width: 1280px) { margin-left: 40px; }
		}
	}
`;

const Service = () => {
	const { t } = useTranslation();
	const params = useParams();
	const id = params?.id;

	useEffect(() => window.scrollTo(0, 0), [id]);

	if (id === 'foreign_hotels') {
		return (
			<>
				<div className='text-block pt-sans padding-80 font-20 color-grey'>
					{t('Размещение по всему миру от глобальных консолидаторов.')}
				</div>

				<div className='divider col-1 margin-bottom-80' />

				<Buttons className='col-1 center-margin margin-bottom-80 flex row align-center justify-center'>
					<Link to="/agent" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
						<img src={pictoAgent} alt="" className='pictogram margin-bottom-20' />
						<p className='fullwidth'>{t('Стать агентом')}</p>
					</Link>

					<Link to="/add_hotel" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
						<img src={pictoSystem} alt="" className='pictogram margin-bottom-20' />
						<p className='fullwidth'>{t('Подключить систему бронирования')}</p>
					</Link>
				</Buttons>

				<Advantages />

				<div className='block-fullwidth bg-blue'>
					<div className='divider col-1 bg-white' />
				</div>
			</>
		);
	}

	if (id === 'gds_ads') {
		return (
			<>
				<div className='text-block pt-sans padding-80 font-20 color-grey'>
					{t('Сделаем Ваш отель доступным для бронирования профессиональными турагентами на 500 000 терминалах по всему миру.')}
				</div>

				<div className='divider col-1 margin-bottom-80' />

				<a href={schemeAdsGds} target="_blank" className='text-block margin-bottom-80' rel="noreferrer">
					<img src={i18n.language === 'ru' ? schemeAdsGds : schemeAdsGdsEn} alt="" className='block-fullwidth' />
				</a>

				<Buttons className='col-1 center-margin padding-80 flex row align-center justify-center'>
					<Link to="/add_hotel" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
						<img src={pictoAddHotel} alt="" className='pictogram margin-bottom-20' />
						{t('Добавить отель')}
					</Link>

					<Link to="/agent" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
						<img src={pictoAgent} alt="" className='pictogram margin-bottom-20' />
						{t('Стать агентом')}
					</Link>
				</Buttons>

				<Advantages />

				<div className='block-fullwidth bg-blue'>
					<div className='divider col-1 bg-white' />
				</div>
			</>
		);
	}

	return (
		<>
			<div className='text-block pt-sans padding-80 font-20 color-grey'>
				{t('Собственная система онлайн-бронирования отелей в России и ближнем зарубежье.')}
			</div>

			<div className='divider col-1 margin-bottom-80' />

			<Buttons className='col-1 center-margin margin-bottom-80 flex row align-center justify-center'>
				<Link to="/add_hotel" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
					<img src={pictoAddHotel} alt="" className='pictogram margin-bottom-20' />
					{t('Добавить отель')}
				</Link>

				<Link to="/agent" className='col-3 flex square flex column align-center justify-center font-bold font-24 text-uppercase color-blue hover text-center'>
					<img src={pictoAgent} alt="" className='pictogram margin-bottom-20' />
					{t('Стать агентом')}
				</Link>
			</Buttons>

			<Advantages />

			<div className='block-fullwidth bg-blue'>
				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
};

export default Service;