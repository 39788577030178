import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FourthWidth, ThirdWidth } from './Media';

const UniButton = styled(Link)`
	display: block;
	${props => props.$borderWhite && `border: 2px solid #FFFFFF`};
	${props => props.$borderBlue && `border: 2px solid #034781`};
	border-radius: 5px;
	${props => props.$width !== false && `flex-shrink: 0; width: ${props.$width}px`};
	${props => props.$widthFourth !== false && FourthWidth};
	${props => props.$widthThird !== false && ThirdWidth};
	${props => props.$textBlue && `color: #034781`};
	${props => props.$textWhite && `color: white`};
	text-decoration: none;
	text-align: center;
	font-size: 14px;
	line-height: 30px;
	height: 30px;
	@media (min-width: 980px) {
		font-size: 16px;
		line-height: 40px;
		height: 40px;
	}
	${props => props.$transparent && `background-color: transparent`};
	transition: color .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out;
	text-transform: uppercase;
	${props => props.$center && `margin-left: auto`};
	${props => props.$center && `margin-right: auto`};
	${props => props.$bottomMargin !== false && `margin-bottom: ${props.$bottomMargin}px`};
	${props => props.$topMargin !== false && `margin-top: ${props.$topMargin}px`};
	
	&:hover {
		${props => props.$hoverBlue && `background-color: #0270CB`};
		${props => props.$hoverBlue && `border: 2px solid #0270CB`};
		${props => props.$hoverBlue && `color: white`};
		
		${props => props.$hoverWhite && `background-color: white`};
		${props => props.$hoverWhite && `color: #034781`};
	}
`;

export const Button = ({
	borderBlue = false,
	borderWhite = false,
	bottomMargin = false,
	center = false,
	children = '',
	className = '',
	hoverBlue = false,
	hoverWhite = false,
	onClick = () => null,
	textBlue = false,
	textWhite = false,
	to = '/',
	topMargin = false,
	transparent = false,
	width = false,
	widthFourth = false,
	widthThird = false,
}) => <UniButton $borderBlue={borderBlue}
	$borderWhite={borderWhite}
	$bottomMargin={bottomMargin}
	             $center={center}
	className={className}
	             $hoverBlue={hoverBlue}
	             $hoverWhite={hoverWhite}
	             $textBlue={textBlue}
	             $textWhite={textWhite}
	$topMargin={topMargin}
	             $transparent={transparent}
	             $width={width}
	             $widthFourth={widthFourth}
	             $widthThird={widthThird}
	onClick={onClick}
	             to={to}
>
	{children}
</UniButton>;
