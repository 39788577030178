import React, {useEffect, useRef, useState} from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {Colors} from 'constants';
import { useTranslation } from 'react-i18next';

const SearchInput = styled.div`
	border: 1px solid ${Colors.blue};
	height: 40px;
	position: relative;
	
	> div {
		background-color: white;
		position: absolute;
		z-index: 10;
		border: 1px solid ${Colors.blue};
		width: calc(100% + 2px);
		left: -1px;
		color: ${Colors.blue};
		box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
		height: 160px;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 16px;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		
		scrollbar-base-color: #61dafb	;
		
		> div {
			padding: 8px;
			
			&.active {
				background-color: #D3EFFF;
				cursor: pointer;
			}
		}
	}
	
	input {
		height: 100%;
		width: 100%;
		line-height: 40px;
		color: ${Colors.blue};
		font-family: 'PT Sans', sans-serif !important;
		background-color: white;
		border: none;
		padding: 0 8px;
		
		&:focus {
			box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
			
			& + div {
				opacity: 1;
				visibility: visible;
			}
		}

		&::placeholder {
			color: rgba(3, 71, 129, 0.7);
		}
	}
`;

let timer = null;

export const Search = observer(props => {
	const { t } = useTranslation();
	const { searchStore, history } = props;
	const resultsDiv = useRef();
	const [ index, setIndex ] = useState(0);
	const [ needle, setNeedle ] = useState('');

	const handleChangeNeedle = e => setNeedle(e.target.value);
	const handleKeyupNeedle = e => {
		if (e.keyCode === 38) {
			setIndex(index > 0 ? index - 1 : searchStore.results.length - 1);
		}

		if (e.keyCode === 40) {
			setIndex(index < searchStore.results.length - 2 ? index + 1 : 0);
		}

		if (e.keyCode === 13) {
			if (resultsDiv.current.children[index] !== undefined) {
				resultsDiv.current.children[index].click();
			}
		}
	};

	useEffect(() => {
		// console.log(index, resultsDiv.current, resultsDiv.current.children[index]);
		// console.log(resultsDiv.current.scrollTop);
		if (resultsDiv.current && resultsDiv.current.children[index] !== undefined) {
			resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});

			const scrollTop = resultsDiv.current.scrollTop;
			const parentHeight = resultsDiv.current.getBoundingClientRect().height;
			const childTop = resultsDiv.current.children[index].offsetTop;
			const childHeight = resultsDiv.current.children[index].getBoundingClientRect().height;

			if (scrollTop + parentHeight < childTop + childHeight) {
				resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});
				/*
				resultsDiv.current.scrollTop({
					top: childTop + childHeight - scrollTop + parentHeight,
					behavior: 'smooth'
				});
				*/
			}

			/*
			console.log({
				scrollTop,
				parentHeight,
				childTop,
				childHeight,
			});
			*/
		}
	}, [index]);

	useEffect(() => {
		if (needle.trim().length >= 2) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				searchStore.fetch(needle);
				setIndex(0);
			}, 500);
		}

		if (needle.trim().length === 0) {
			searchStore.setResults([]);
		}
	}, [needle]);

	const handleClick = (typeCode, code) => {
		if (typeCode === 1) {
			history.push(`/country/${code}`);
		}

		if (typeCode === 2) {
			history.push(`/city/0-${code}`);
		}

		if (typeCode === 3) {
			history.push(`/hotel/hotel-${code}`);
		}
	};

	const results = searchStore.countries
		.filter(i => i.Name.toLowerCase().includes(needle.trim().toLowerCase()))
		.map(i => ({TypeCode: 1, Code: i.Code, Name: i.Name}))
		.concat(searchStore.results.map(i => ({TypeCode: i.TypeCode, Code: i.Code, Name: i.Name})))
	;

	return (
		<SearchInput className='text-block-full'>
			<input type='text' placeholder={t('Введите страну, город, название отеля')} value={needle} onChange={handleChangeNeedle} onKeyUp={handleKeyupNeedle} />
			{searchStore.results.length > 0 && <div ref={resultsDiv}>
				{results.map((i, key) =>
					<div key={key} onMouseEnter={() => setIndex(key)} onClick={() => handleClick(i.TypeCode, i.Code)} className={key === index ? 'active' : ''}>{i.Name}</div>
				)}
			</div>}
		</SearchInput>
	);
});