import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useClickOutside } from 'hooks';
import { Colors } from 'constants';

const flagRU = <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0 0V3.99989V7.99981V11.9997H17.9996V7.99981V3.99989V0H0Z" fill="#F0F0F0"/>
	<path d="M17.9996 0H0V11.9997H17.9996V0Z" fill="#0052B4"/>
	<path d="M17.9996 0H0V3.99989H17.9996V0Z" fill="#EDEDED"/>
	<path d="M17.9996 7.99981H0V11.9997H17.9996V7.99981Z" fill="#FF153F"/>
</svg>;

const flagEN = <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M18 9.15527e-05H0V12H18V9.15527e-05Z" fill="#F0F0F0"/>
	<path d="M10.125 0H7.875V4.87488H0V7.12484H7.875V11.9997H10.125V7.12484H18V4.87488H10.125V0Z" fill="#D80027"/>
	<path d="M13.8439 8.08676L17.9999 10.3956V8.08676H13.8439Z" fill="#0052B4"/>
	<path d="M10.9565 8.08676L18 11.9997V10.8932L12.9483 8.08676H10.9565Z" fill="#0052B4"/>
	<path d="M16.1237 11.9997L10.9564 9.12878V11.9997H16.1237Z" fill="#0052B4"/>
	<path d="M10.9565 8.08676L18 11.9997V10.8932L12.9483 8.08676H10.9565Z" fill="#F0F0F0"/>
	<path d="M10.9565 8.08676L18 11.9997V10.8932L12.9483 8.08676H10.9565Z" fill="#D80027"/>
	<path d="M3.17605 8.08669L0 9.85114V8.08669H3.17605Z" fill="#0052B4"/>
	<path d="M7.04353 8.58427V11.9997H0.89621L7.04353 8.58427Z" fill="#0052B4"/>
	<path d="M5.05171 8.08676L0 10.8932V11.9997L7.04348 8.08676H5.05171Z" fill="#D80027"/>
	<path d="M4.156 3.91296L0 1.60408V3.91296H4.156Z" fill="#0052B4"/>
	<path d="M7.04348 3.91296L0 0V1.10649L5.05171 3.91296H7.04348Z" fill="#0052B4"/>
	<path d="M1.87622 0L7.04356 2.87094V0H1.87622Z" fill="#0052B4"/>
	<path d="M7.04348 3.91296L0 0V1.10649L5.05171 3.91296H7.04348Z" fill="#F0F0F0"/>
	<path d="M7.04348 3.91296L0 0V1.10649L5.05171 3.91296H7.04348Z" fill="#D80027"/>
	<path d="M14.8237 3.91302L17.9998 2.14856V3.91302H14.8237Z" fill="#0052B4"/>
	<path d="M10.9565 3.41543V3.05176e-05H17.1038L10.9565 3.41543Z" fill="#0052B4"/>
	<path d="M12.9483 3.91296L18 1.10649V0L10.9565 3.91296H12.9483Z" fill="#D80027"/>
</svg>;

const arrowDown = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M1 1L6 6L11 1" stroke="#004685" strokeLinecap="round"/>
</svg>;

const arrowUp = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11 6L6 1L1 6" stroke="#004685" strokeLinecap="round"/>
</svg>;

const Container = styled.div`
	width: 76px;
	position: relative;
	z-index: 1001;

	label, div {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: ${Colors.blue};

		&:hover {
			color: ${Colors.lightblue};
		}
	}

	label {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		cursor: pointer;
	}

	> div {
		border-radius: 2px;
		border: 1px solid #80A2C2;
		background-color: white;
		padding: 5px;
		top: calc(100% + 2px);
		left: 0;
		right: 0;
		position: absolute;
		display: flex;
		flex-direction: column;
		gap: 5px;

		div {
			display: flex;
			align-items: center;
			gap: 5px;
			cursor: pointer;
		}
	}
`;

const Lang = () => {
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const lang = i18n.language;

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		setOpen(false);
		window.location.reload();
	};

	const onToggle = () => setOpen(prev => !prev);

	return (
		<Container ref={ref} id='lang_switcher'>
			<label onClick={onToggle}>
				{lang === 'ru' && <>{flagRU}РУС</>}

				{lang !== 'ru' && <>{flagEN}ENG</>}

				{isOpen ? arrowUp : arrowDown}
			</label>
			
			{isOpen && <div>
				<div onClick={changeLanguage.bind(this, 'ru')}>
					{flagRU}РУС
				</div>

				<div onClick={changeLanguage.bind(this, 'en')}>
					{flagEN}ENG
				</div>
			</div>}
		</Container>
	);
};

export default Lang;
