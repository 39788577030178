import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Colors} from 'constants';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchInput = styled.div`
	border: 1px solid ${Colors.blue};
	height: 40px;
	position: relative;
	
	> div {
		background-color: white;
		position: absolute;
		z-index: 10;
		border: 1px solid ${Colors.blue};
		width: calc(100% + 2px);
		left: -1px;
		color: ${Colors.blue} !important;
		box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
		height: 160px;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 16px;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		
		scrollbar-base-color: #61dafb	;
		
		> div {
			padding: 8px;
			color: ${Colors.blue} !important;
			
			&.active {
				background-color: #D3EFFF;
				cursor: pointer;
			}
		}
	}
	
	input {
		height: 100%;
		width: 100%;
		line-height: 40px;
		color: ${Colors.blue};
		font-family: 'PT Sans', sans-serif !important;
		background-color: white;
		border: none;
		padding: 0 8px;
		
		&:focus {
			box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
			
			& + div {
				opacity: 1;
				visibility: visible;
			}
		}

		&::placeholder {
			color: rgba(3, 71, 129, 0.7);
		}
	}
`;

const HotelContainer = styled.div`
	flex-direction: column;
	align-items: center;
	
	@media (min-width: 540px) {
		flex-direction: row;
		align-items: flex-start;
	}

	img {
		@media (min-width: 540px) { margin-right: 20px; }
		@media (max-width: 539px) { margin-bottom: 10px; }
	}
	
	div {
		@media (max-width: 539px) {
			align-items: center;
			p { text-align: center; }
		}
	}
`;

const EnterButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 2px solid #FFFFFF;
	border-radius: 5px;
	cursor: pointer;
	
	font-size: 14px;
	line-height: 100% !important;
	height: 30px;
	width: 100%;
	text-transform: uppercase;
	background-color: transparent;
	color: white;
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	
	@media (min-width: 540px) {
		width: 60px;	
	}
	
	@media (min-width: 980px) {
		font-size: 16px;
		width: 80px;
		height: 40px;
		//line-height: 40px;
	}
	
	&:hover {
		color: ${Colors.blue};
		background-color: white;
	}
`;

const HotelImg = styled.img`
	border: 2px solid white;
	object-fit: cover;
`;

const AddHotelButton = styled(Link)`
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: transparent;
	border: 2px solid white;
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	width: 160px;
	border-radius: 5px;
	
	&:hover {
		color: ${Colors.blue};
		background-color: white;
	}
	
	@media (min-width: 1280px) {
		height: 40px;
	}
`;

let timer = null;

export const CheckHotel = observer(props => {
	const { t } = useTranslation();
	const { handleClickShow, checkHotelStore } = props;
	const resultsDiv = useRef();
	const [ index, setIndex ] = useState(0);
	const [ needle, setNeedle ] = useState('');

	useEffect(() => {
		return checkHotelStore.clear;
	}, []);

	const handleChangeNeedle = e => setNeedle(e.target.value);

	const handleClick = id => checkHotelStore.fetchHotel(id);

	useEffect(() => {
		if (needle.trim().length >= 2) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				checkHotelStore.fetch(needle);
				setIndex(0);
			}, 500);
		}

		if (needle.trim().length === 0) {
			checkHotelStore.hotels = null;
		}
	}, [needle]);

	const handleMouseEnter = key => setIndex(key);

	return (
		<div className='col-2'>
			<SearchInput className='col-2 margin-bottom-20'>
				<input type='text' placeholder={t('Введите название отеля')} value={needle} onChange={handleChangeNeedle} />
				{checkHotelStore.hotels !== null && checkHotelStore.hotels.length > 0 && <div ref={resultsDiv}>
					{checkHotelStore.hotels.map((i, key) =>
						<div key={key} onMouseEnter={handleMouseEnter.bind(this, key)} onClick={handleClick.bind(this, i.Code)} className={classNames({'active': key === index})}>{i.Name}</div>
					)}
				</div>}
			</SearchInput>

			{checkHotelStore.hotel === null && checkHotelStore.hotels === null && <p className='pt-sans font-16'>{t('Проверьте наличие отеля в базе «АКАДЕМ-ОНЛАЙН».')}</p>}

			{checkHotelStore.hotels !== null && checkHotelStore.hotels.length === 0 &&
				<>
					<p className='pt-sans font-16 margin-bottom-20'>{t('Отель не является партером «АКАДЕМ-ОНЛАЙН».')}<br/>{t('Вы можете добавить его в нашу базу.')}</p>
					<AddHotelButton to='/add_hotel' className='font-16 text-uppercase'>{t('Добавить отель')}</AddHotelButton>
				</>
			}

			{checkHotelStore.hotel !== null && <HotelContainer className='col-2 flex justify-between'>
				{checkHotelStore.hotel.Images.Image.length > 0 && <HotelImg src={checkHotelStore.hotel.Images.Image[0].Url ?? checkHotelStore.hotel.Images.Image[0].ThUrl} alt={t('Балчуг Кемпински Москва')} className='pictogram flex' />}

				<div className='flex column color-white'>
					<p className='font-20 font-bold margin-bottom-10'>{checkHotelStore.hotel.Name}</p>
					<p className='font-16 margin-bottom-20'>{t('Отель является партером «АКАДЕМ-ОНЛАЙН». Для дальнейшей работы, пожалуйста, войдите в систему бронирования.')}</p>
					<EnterButton href='/' onClick={handleClickShow}>{t('Войти')}</EnterButton>
				</div>
			</HotelContainer>}
		</div>
	);
});