import React, {useEffect} from 'react';
import pictoWarning from 'images/picto_warning.svg';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
	const { t } = useTranslation();
	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<div className='col-1 flex column align-center padding-80'>
			<img src={pictoWarning} alt='' className='width-picto margin-bottom-20' />

			<p className='text-center text-uppercase color-blue font-24 font-bold'>{t('Попробуйте начать с')} <Link to='/' className='color-blue hover text-underline'>{t('главной страницы')}</Link></p>
		</div>
	);
};

export default Error404;