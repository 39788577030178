import React from 'react';
import styled from 'styled-components';
import {FullWidth, TextWidth} from './Media';

const BlueLineDiv = styled.div`
	height: 1px;
	background-color: #034781;
	//margin-bottom: ${props => props.$bottomMargin}px;
	${props => props.$textWidth ? TextWidth : FullWidth}
`;

export const BlueLine = ({bottomMargin = 80, className = '', textWidth = false}) => <BlueLineDiv className={`margin-bottom ${className}`} $textWidth={textWidth} $bottomMargin={bottomMargin} />;