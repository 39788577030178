import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Filler, Lang, Login} from './';
import logoImg from 'images/logo.svg';
import {Link, NavLink, useLocation} from 'react-router-dom';
import pictoMenu from 'images/picto_menu.svg';
import pictoMenuClose from 'images/picto_menu_close.svg';
import pictoSubmenu from 'images/picto_submenu.svg';
import { Colors } from 'constants';
import flagRU from 'images/flag_ru.svg';
import flagEN from 'images/flag_en.svg';
import flagCN from 'images/flag_cn.svg';
import { useTranslation } from 'react-i18next';

const MenuDiv = styled.div`
	height: 60px;
	@media (min-width: 980px) { height: 80px; }
	padding: 0 20px;
	@media (min-width: 1280px) { padding: 0 40px; }
	
	position: sticky;
	left: 0;
	top: 0;
	
	z-index: 1000;
	background-color: ${Colors.white};
	border-bottom: 1px solid #E6EDF3;

	> ul {
		@media (max-width: 979px) { display: none; }
		
		li {
			position: relative;

			a {
				margin: 0 3px;
				transition: all .2s ease-in-out;
				background-color: white;
				padding: 2px 5px;
				
				&.active, &:hover {
					color: ${Colors.lightblue};
					background-color: #D3EFFF;
				}
			}
			
			> ul {
				position: absolute;
				top: 29px;
				background-color: ${Colors.white};
				width: 260px !important;
				border: 1px solid ${Colors.blue};
				padding: 20px 17px;
				left: -80px;
				opacity: 0;
				visibility: hidden;
				transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
				
				li {
					a {
						margin: 0;
						text-transform: none;
						padding: 2px 5px;
						display: block;
					}
					
					&:nth-child(n + 2) {
						margin-top: 20px;
					}
				}
			}
			
			&:hover {
				> ul {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
`;

const LoginButton = styled(Link)`
	margin-left: 10px;
	width: 60px;
	height: 30px;
	@media (min-width: 980px) {
		width: 80px;
		height: 40px;
	}
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	border-radius: 5px;
	text-transform: uppercase;
	color: ${Colors.white};
	transition: background .2s ease-in-out;
	
	&:hover {
		background: linear-gradient(180deg, #0270CB 0%, #0270CB 100%);
	}
`;

const Logo = styled(Link)`
	font-size: 14px;
	line-height: 17px;
	@media (min-width: 980px) {
		font-size: 28px;
		line-height: 33px;
	}
	font-weight: 500;
	color: ${Colors.blue};
	position: relative;

	&:before {
		content: '';
		background-image: url(${logoImg});
		background-size: contain;
		background-repeat: no-repeat;
		width: 30px;
		height: 12px;
		margin-right: 5px;

		@media (min-width: 980px) {
			height: 20px;
			width: 48px;
		}
	}

	span { color: ${Colors.lightblue}; }
`;

const MenuSwitch = styled.div`
	width: 30px;
	height: 22px;
	background-image: url(${pictoMenu});
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 10px;
	@media (min-width: 980px) { display: none; }

`;

const MobileMenu = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1011;
	background: rgba(3, 71, 129, 0.8);
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	&.active {
		opacity: 1;
		visibility: visible;
		
		> div {
			right: 0;
		}
	}
	
	> div {
		width: 320px;
		padding: 20px;
		background-color: white;
		position: fixed;
		top: 0;
		right: -320px;
		transition: right .2s ease-in-out;
		bottom: 0;
		
		> div:first-child {
			margin-bottom: 20px;
			position: relative;
			
			select {
				display: flex !important;
				font-size: 14px;
				height: 30px;
				width: 80px;
				appearance: none;
				border: none;
				line-height: 32px;
				font-weight: 500;
				margin: 0 0 0 30px;
				padding-left: 3px;
				
				& + label {
					content: '';
					height: 20px;
					width: 30px;
					display: block;
					position: absolute;
					left: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}
				
				&[data-lang="ru"] + label { background-image: url(${flagRU}); }
				&[data-lang="en"] + label { background-image: url(${flagEN}); }
				&[data-lang="cn"] + label { background-image: url(${flagCN}); }
				
				option {
					padding-left: 5px;
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
				}
			}
			
			a.close {
				width: 24px;
				height: 24px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url(${pictoMenuClose});
			}
		}
		
		> ul {
			> li {
				> a {
					font-size: 16px;
					line-height: 19px;
					text-transform: uppercase;
					color: #034781;
					text-decoration: none;
					transition: all .2s ease-in-out;
					background-color: white;
					padding: 2px 5px;
					margin-bottom: 10px;
					display: block;
					
					&.active {
						color: #0270CB;
						background-color: #D3EFFF;
					}
				}
				
				input {
					display: none;
				
					& + a {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
					
						label {
							width: 22px;
							height: 15px;
							display: flex;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: center bottom;
							background-image: url(${pictoSubmenu});
							transition: transform .2s ease-in-out;
						}
						
						& + ul {
							opacity: 0;
							visibility: hidden;
							transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
							position: absolute;
						}
					}
					
					&:checked + a {
						label {
							transform: scaleY(-1);
						}
						
						& + ul {
							opacity: 1;
							visibility: visible;
							position: relative;
						}
					}
				}
				
				ul {
					li {
						a {
							font-size: 16px;
							line-height: 19px;
							color: #034781;
							text-decoration: none;
							transition: all .2s ease-in-out;
							background-color: white;
							padding: 2px 5px;
							margin-bottom: 8px;
							display: block;
							
							&:before {
								content: '— ';
							}
							
							&.active {
								color: #0270CB;
								background-color: #D3EFFF;
							}
						}
					}
				}
			}
		}
	}
`;

const MobileMenuDivider = styled.div`
	width: 100%;
	height: 1px;
	background-color: #E6EDF3;
	margin-bottom: 20px;
`;

export const Menu = () => {
	const { t } = useTranslation();
	const [ isMenuOpen, setIsMenuOpen ] = useState(false);
	const [ isShow, setIsShow ] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsMenuOpen(false);
		setIsShow(false);
	}, [location.pathname]);

	const handleClickMobileMenu = e => {
		e.preventDefault();
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<MenuDiv className='block-fullwidth flex row align-center justify-between'>
			<Logo className='flex row align-center justify-start' to="/" onClick={() => window.scrollTo(0, 0)}>{t('АКАДЕМ')}-<span>{t('ОНЛАЙН')}</span></Logo>

			<Filler />

			<ul className='flex row font-16 color-blue text-uppercase'>
				<li>
					<NavLink to="/services">{t('Услуги')}</NavLink>
					<ul>
						<li><NavLink to="/services/online_booking">{t('Система')}<br/>{t('онлайн-бронирования')}</NavLink></li>
						<li><NavLink to="/services/gds_ads">{t('GDS/ADS провайдинг')}</NavLink></li>
						<li><NavLink to="/services/foreign_hotels">{t('Зарубежные отели')}</NavLink></li>
					</ul>
				</li>
				<li><NavLink to="/add_hotel">{t('Добавить отель')}</NavLink></li>
				<li><NavLink to="/agent">{t('Стать агентом')}</NavLink></li>
				<li>
					<NavLink to="/techs">{t('ТЕХНОЛОГИИ')}</NavLink>
					<ul>
						<li><NavLink to="/techs/for_hotels">{t('Интеграция для отелей')}</NavLink></li>
						<li><NavLink to="/techs/for_agents">{t('Интеграция для агентов')}</NavLink></li>
						<li><NavLink to="/techs/where">{t('Где мы представлены')}</NavLink></li>
					</ul>
				</li>
			</ul>

			<LoginButton className='flex align-center justify-center font-16' onClick={e => { e.preventDefault(); setIsShow(true); }} to='/'>{t('Войти')}</LoginButton>

			<MenuSwitch onClick={handleClickMobileMenu} />

			<MobileMenu className={isMenuOpen ? 'active' : ''}>
				<div>
					<div className='flex row justify-between align-center'>
						<Lang />
						<div className='flex fill' />
						<a href="/" className="close" onClick={handleClickMobileMenu} />
					</div>

					<MobileMenuDivider/>

					<ul>
						<li>
							<input type="checkbox" id="submenu_services" />
							<NavLink to="/services">
								{t('Услуги')}
								<label htmlFor="submenu_services" onClick={e => e.stopPropagation()} />
							</NavLink>
							<ul>
								<li><NavLink to="/services/online_booking">{t('Система')}<br/>{t('онлайн-бронирования')}</NavLink></li>
								<li><NavLink to="/services/gds_ads">{t('GDS/ADS провайдинг')}</NavLink></li>
								<li><NavLink to="/services/foreign_hotels">{t('Зарубежные отели')}</NavLink></li>
							</ul>
						</li>
						<li><NavLink to="/add_hotel">{t('Добавить отель')}</NavLink></li>
						<li><NavLink to="/agent">{t('Стать агентом')}</NavLink></li>
						<li>
							<input type="checkbox" id="submenu_techs" />
							<NavLink to="/techs">
								{t('ТЕХНОЛОГИИ')}
								<label htmlFor="submenu_techs" onClick={e => e.stopPropagation()} />
							</NavLink>
							<ul>
								<li><NavLink to="/techs/for_hotels">{t('Интеграция для отелей')}</NavLink></li>
								<li><NavLink to="/techs/for_agents">{t('Интеграция для агентов')}</NavLink></li>
								<li><NavLink to="/techs/where">{t('Где мы представлены')}</NavLink></li>
							</ul>
						</li>
					</ul>

					<MobileMenuDivider/>

					<ul>
						<li><NavLink to="/about">{t('О нас')}</NavLink></li>
						<li><NavLink to="/news">{t('Новости')}</NavLink></li>
						<li><NavLink to="/contacts">{t('Контакты')}</NavLink></li>
						<li><NavLink to="/countries">{t('Отели')}</NavLink></li>
					</ul>
				</div>
			</MobileMenu>

			<Login isShow={isShow} setIsShow={setIsShow} />
		</MenuDiv>
	);
};