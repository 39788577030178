import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import systemImg from 'images/ic-system.svg';
import providingImg from 'images/ic-providing.svg';
import hotelsImg from 'images/ic-hotels.svg';
import { useTranslation } from 'react-i18next';

const Services = () => {
	const { t } = useTranslation();

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='adv-list col-1 padding-80'>
				<Link to="/services/online_booking" className='col-3'>
					<div className='margin-bottom-20 pictogram'>
						<img src={systemImg} alt='' />
					</div>
					<p className='color-blue text-center font-24 margin-bottom-20'>{t('Система онлайн-бронирования')}</p>
					<p className='pt-sans font-18 text-center color-grey'>{t('Собственная система онлайн-бронирования отелей в России и ближнем зарубежье.')}</p>
				</Link>

				<Link to="/services/gds_ads" className='col-3'>
					<div className='margin-bottom-20 pictogram'>
						<img src={providingImg} alt='' />
					</div>
					<p className='color-blue text-center font-24 margin-bottom-20'>{t('GDS/ADS провайдинг')}</p>
					<p className='pt-sans font-18 text-center color-grey'>{t('Сделаем Ваш отель доступным для бронирования профессиональными турагентами на 500 000 терминалах по всему миру.')}</p>
				</Link>

				<Link to="/services/foreign_hotels" className='col-3'>
					<div className='margin-bottom-20 pictogram'>
						<img src={hotelsImg} alt='' />
					</div>
					<p className='color-blue text-center font-24 margin-bottom-20'>{t('Зарубежные отели')}</p>
					<p className='pt-sans font-18 text-center color-grey'>{t('Размещение по всему миру от глобальных консолидаторов.')}</p>
				</Link>
			</div>
		</>
	);
};

export default Services;