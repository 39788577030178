import React, {useEffect} from 'react';
import styled from 'styled-components';
import pictoMap from 'images/picto_map.svg';
import pictoPhone from 'images/picto_phone.svg';
import pictoEmail from 'images/picto_email.svg';
// import map1 from 'images/map-1.png';
import pictoDoc from 'images/picto_doc.svg';
import belous from 'images/s_belous.jpg';
import zubchenkova from 'images/i_zubchenkova.jpg';
import GoogleMapReact from 'google-map-react';
import markerContacts from 'images/marker-contacts.svg';
import { useTranslation } from 'react-i18next';

const HotelMarker = styled.div`
	width: 40px;
	height: 54px;
	// background-image: url(https://images.acase.ru/gmapIcons/3-9500001-p.png);
	background-image: url(${markerContacts});
	background-repeat: no-repeat;
	transform: translate(-20px, -100%);
`;

/*
const MapImg = styled.img`
	@media (max-width: 979px) { margin-bottom: 40px; }
`;
 */

const Icon = styled.div`
	@media (max-width: 979px) {
		margin-bottom: 20px;
	}
`;

const MapContainer = styled.div`
	flex-direction: column;
	padding: 20px 0 40px 0;
	
	@media (min-width: 980px) {
		flex-direction: row;
		padding: 80px 0;
	}
`;

const Button = styled.a`
	@media (max-width: 539px) {
		flex-direction: column !important;
		padding: 20px;
	}

	img {
		@media (min-width: 540px) { margin-right: 20px; }
		@media (max-width: 539px) { margin-bottom: 10px; }
	}
`;

const MapDiv = styled.div`
	position: relative;
	
	@media (max-width: 979px) { margin-bottom: 40px; }
	
	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;	
	}
	
	> div {
		position: absolute;
		width: 100%;
		height: 100%;
	}
`;

const Contacts = () => {
	const { t } = useTranslation();

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='col-1 flex row wrap justify-between padding-80'>
				<Icon className='col-3 full-320 flex column align-center color-blue font-bold'>
					<img src={pictoMap} alt='' className='margin-bottom-20' />
					<p className='margin-bottom-20 text-uppercase font-24'>{t('Наш адрес')}:</p>
					<p className='font-18 center-text'>{t('115114, г.Москва, Дербеневская набережная, д.11')}, {t('БЦ')} &laquo;{t('Полларс')}&raquo;, {t('корп. В, 4 этаж')}</p>
				</Icon>

				<Icon className='col-3 full-320 flex column align-center color-blue font-bold'>
					<img src={pictoPhone} alt='' className='margin-bottom-20' />
					<p className='margin-bottom-20 text-uppercase font-24'>{t('Телефон')}:</p>
					<a className='font-18' href="tel:+74956609090">+7 495 660-90-90</a>
				</Icon>

				<div className='col-3 full-320 flex column align-center color-blue font-bold'>
					<img src={pictoEmail} alt='' className='margin-bottom-20' />
					<p className='margin-bottom-20 text-uppercase font-24'>E-mail:</p>
					<a className='font-18' href="mailto:info@acase.ru">info@acase.ru</a>
				</div>
			</div>

			<div className='divider col-1' />

			<div className='col-1 text-center color-blue font-bold font-24 padding-80'>
				{t('Круглосуточная служба технической поддержки')}:<br/>
				<a href="tel:+79852318879">+7 985 231-88-79</a>
			</div>

			<Button href='/docs/academonline.pdf' className='button margin-bottom-80 col-1 font-24 font-bold'>
				<img src={pictoDoc} alt='' />
				{t('Скачать реквизиты')}
			</Button>

			<div className='col-1 flex row wrap justify-between margin-bottom-40'>
				<div className='col-2 flex column align-center color-blue font-bold font-18 margin-bottom-40'>
					<img src={belous} alt='' className='pictogram margin-bottom-20' />
					<p className='text-uppercase text-center font-24 margin-bottom-20'>{t('Департамент продаж')}</p>
					<p className='text-center margin-bottom-10'>{t('Директор департамента')}<br/>{t('Сергей Белоус')}</p>
					<a className='margin-bottom-10' href="tel:+74956609090">+7 495 660-90-90</a>
					<a href="mailto:s.belous@acase.ru">s.belous@acase.ru</a>
				</div>

				<div className='col-2 flex column align-center color-blue font-bold font-18 margin-bottom-40'>
					<img src={zubchenkova} alt='' className='pictogram margin-bottom-20' />
					<p className='text-uppercase text-center font-24 margin-bottom-20'>{t('департамент контрактинга')}</p>
					<p className='text-center margin-bottom-10'>{t('Директор департамента')}<br/>{t('Ирина Зубченкова')}</p>
					<a className='margin-bottom-10' href="tel:+74956609090">+7 495 660-90-90</a>
					<a href="mailto:i.zubchenkova@acase.ru">i.zubchenkova@acase.ru</a>
				</div>
			</div>

			<div className='block-fullwidth bg-blue'>
				<MapContainer className='col-1 flex justify-between'>
					<MapDiv className='col-2'>
						<div>
							<GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyBoO5bO7h87PFrFiV2mejukKsWNWv71pj0 ' }}
								defaultCenter={{ lat: 55.721265, lng: 37.652201 }}
								defaultZoom={17}
								yesIWantToUseGoogleMapApiInternals={true}
							>
								<HotelMarker lat={55.721265} lng={37.652201} text='' />
							</GoogleMapReact>
						</div>
					</MapDiv>

					<div className='col-2 color-white font-18'>
						<p className='font-bold font-24 text-uppercase margin-bottom-20'>{t('Время работы')}:</p>
						<p className='font-bold margin-bottom-20'>{t('Пн. - Пт. с 9:30 до 18:00')}</p>

						<p className='font-bold'>{t('Как добраться пешком')}:</p>
						<p className='pt-sans'>{t('Станция Павелецкая Замоскворецкой линии метро, выход №3. Сразу при выходе из здания Павелецкого вокзала повернуть направо и вдоль трамвайных путей двигаться по Кожевнической улице до дома №10. Повернуть направо на Летниковскую улицу и идти до Жукова проезда. Повернуть налево и идти до пересечения с Дербеневской улицей. Пересечь Дербеневскую улицу и продолжить движение прямо в переулок между домами, дом №11 по Дербеневской улице должен остаться справа. Далее, идти вдоль кованого металлического забора до шлагбаума. Пройти через шлагбаум к центральной проходной корпуса В.')}</p>
						<p className='pt-sans'>{t('На проходной вам понадобятся документы удостоверяющие личность. Если у вас возникнут сложности на охране, позвоните нам.')}</p>
						<p className='pt-sans margin-bottom-20'>{t('За турникетом главной проходной, поднимитесь на лифте на 4 этаж, где располагается офис компании.')}</p>

						<p className='font-bold'>{t('Как доехать общественным транспортом')}:</p>
						<p className='pt-sans'>{t('Станция Павелецкая. Маршруты автобусов: 913, 932 до остановки «Дербеневская набережная 7».')}</p>
						<p className='pt-sans margin-bottom-20'>{t('Выйдя из автобуса, повернуть налево и пройти в сторону продолжения следования маршрута мимо домов 7 строение 1 и 11А по Дербеневской набережной. Далее свернуть направо и идти прямо к проходной центрального корпуса В.')}</p>

						<p className='font-bold'>{t('Как доехать на автомобиле')}:</p>
						<p className='pt-sans'>{t('Заезд на территорию бизнес центра «Полларс» с Дербеневской набережной. Автомобиль можно разместить на одной из городских парковок, удобнее всего останавливаться при движении в сторону центра.')}</p>
					</div>
				</MapContainer>

				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
};

export default Contacts;