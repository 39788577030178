import React from 'react';
import styled from 'styled-components';
// import {ThirdWidth} from "./Media";

const Container = styled.div`
`;

export const LightBlueBlock = ({children = null}) => {
	return (
		<Container className='padding-top padding-bottom'>
			{children}
		</Container>
	);
};