import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const CoverDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	background-image: url(${props => props.bg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	@media (min-width: 980px) {
		padding-top: 120px !important;
		padding-bottom: 120px !important;
	}
	@media (max-width: 979px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	&:before {
		content: '';
		background-color: rgba(3, 71, 129, 0.6);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}
	
	h1 {
		z-index: 20;
	}
	
	> div {
		height: 40px;
		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		line-height: 17px;
		color: #034781;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 11;
		@media (max-width: 979px) { display: none; }
		
		a {
			text-decoration: none;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 17px;
			color: #034781;
			margin: 0 5px;
			
			&:last-child {
				color: #0270CB;
			}
			
			&:hover {
				color: #0270CB;
			}
		}
	}
`;

export const Cover = ({breadcrumbs = [], children = null, bg = '', withoutUppercase = false}) => (
	<CoverDiv bg={bg} $withoutUppercase={withoutUppercase} className='padding-top padding-bottom'>
		{children}

		{breadcrumbs.length > 0 && <div>
			{breadcrumbs.map((b, key) => <Link key={key} to={b.link}>{b.label}</Link>).reduce((prev, curr) => [prev, '>', curr])}
		</div>}
	</CoverDiv>
);