import React, { useEffect } from 'react';
import {CityHotels, Search} from 'components';
import {observer} from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

const City = observer(props => {
	const params = useParams();
	const countryId = parseInt(params.id.split('-')[0]);
	const cityId = parseInt(params.id.split('-')[1]);
	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='text-block padding-80'>
				<Search {...props} />
			</div>

			<CityHotels {...props} countryId={countryId} cityId={cityId} />
		</>
	);
});

export default City;