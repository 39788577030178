import { useLocalObservable } from 'mobx-react-lite';
import {action} from 'mobx';
import i18n from 'i18next';

export const useCountriesStore = mainStore => useLocalObservable(() => ({
	countries: [],
	setCountries: action(function(countries) { this.countries = countries; }),

	request() {
		return {
			CountryRequest: {
				UserType: "buyer",
				BuyerId: "RO1BAND",
				UserId: "WWW",
				Password: "84765",
				Language: i18n.language,
				ProfitCentreCode: "",
				SUserCode: "",
				IsSyncOtgr: "",
				ActionPubliclist: {
					Parameters: {}
				}
			}
		};
	},
	fetch() {
		mainStore.ajax(this.request(), data => this.setCountries(data.CountryResponse.CountryList.Country.filter(i => i.Name[0] !== ' ')));
	},
	clear() {
		this.setCountries([]);
	}
}));