const Colors = {
	white: 'white',
	blue: '#034781',
	lightblue: '#0270CB',
	grey: '#333333',
	lightgrey: '#8D8D8D',
};

export {
	Colors,
};