export const Pictogram = `
	flex-grow: 0;
	flex-shrink: 0;
	width: 200px;
	height: 200px;

	@media (max-width: 1600px) {
		width: 160px;
		height: 160px;
	}

	@media (max-width: 1280px) {
		width: 140px;
		height: 140px;
	}
`;