import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {CityHotels} from 'components';
import { observer } from 'mobx-react-lite';
import GoogleMapReact from 'google-map-react';
// import hotelMarkerPng from 'images/hotel_marker.png';
import {Colors} from 'constants';
import arrow from 'images/whiteArrowLeft.svg';
import arrowBlue from 'images/arrowBlue.svg';
import close from 'images/close.svg';
import pictoNoPhoto from 'images/picto_no_photo.png';
// import behLogo from 'images/beh_logo.svg';
import behLogo from 'images/BEH_logo_bg.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Amenity = styled.div`
	img {
		margin-right: 10px;
		width: 30px;
		height: 30px;
		object-fit: contain;
		object-position: center;
	}
`;

const Thumb = styled.img`
	line-height: 0;
	width: 48px;
	@media (min-width: 660px) { width: 116px; }
	@media (min-width: 980px) { width: 84px; }
	@media (min-width: 1280px) { width: 104px; }
	@media (min-width: 1600px) { width: 128px; }
	cursor: pointer;
	
	&:nth-child(n + 2) { margin-left: 10px; }
`;

const InfoBlock = styled.div`
	padding-bottom: 10px;
	border-bottom: 1px solid white;
`;

const MapContainer = styled.div`
	height: 280px;
	@media (min-width: 660px) { height: 480px; }
`;

/*
const HotelMarker = styled.img`
	width: 25px;
	height: 23px;
	background-image: url(https://images.acase.ru/gmapIcons/3-9500001-p.png);
	background-repeat: no-repeat;
`;
*/

const MyMarker = styled.div`
	width: 25px;
	height: 23px;
	background-image: url(${props => props.$iconUrl});
	background-repeat: no-repeat;
	position: relative;
	border: none !important;
	
	> div {
		position: absolute;
		left: 26px;
		bottom: 24px;
		background-color: white;
		border: 1px solid #666;
		border-radius: 5px;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		padding: 5px 10px;
		white-space: nowrap;
		z-index: 10;
	}
	
	&:hover > div {
		opacity: 1;
		visibility: visible;
	}
`;

const MoreImages = styled.div`
	margin-left: 10px;
	@media (min-width: 660px) { width: 116px; }
	@media (min-width: 980px) { width: 84px; }
	@media (min-width: 1280px) { width: 104px; }
	@media (min-width: 1600px) { width: 128px; }
	line-height: 0 !important;
	width: 48px;
	position: relative;
	
	img { z-index: 10; }
	
	div {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 11;
		background: rgba(3, 71, 129, 0.5);
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
`;

const BigImage = styled.img`
	cursor: pointer;
`;

const GalleryContainer = styled.div`
	@media (max-width: 979px) {
		//border-top: 1px solid ${Colors.blue};
		//border-bottom: 1px solid ${Colors.blue};
		//padding: 20px 0;
		margin-bottom: 20px;
		position: relative;
		width: calc(100% - 40px);
	}
	
	@media (min-width: 980px) {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1001;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		background: rgba(3, 71, 129, 0.8);
		${props => props.$isShow && `opacity: 1; visibility: visible;`}
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const Prev = styled.div`
	width: 18px;
	height: 30px;
	@media (min-width: 980px) { background-image: url(${arrow}); }
	@media (max-width: 979px) {
		background-image: url(${arrowBlue});
		background-color: white;
		background-size: 40%;
		background-repeat: no-repeat;
		width: 30px;
		border-radius: 15px;
		background-position: 45% center;
	}
	background-position: center;
	background-size: cover;
	//margin-left: 40px;
	//@media (max-width: 1280px) { margin-left: 20px; };
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	position: absolute;
	left: 10px;
	@media (min-width: 660px) { left: 20px; }
	z-index: 20;
	
	${props => props.$isHidden && `opacity: 0; visibility: hidden;`}
`;

const Next = styled.div`
	width: 18px;
	height: 30px;

	@media (min-width: 980px) { background-image: url(${arrow}); }
	@media (max-width: 979px) {
		background-image: url(${arrowBlue});
		background-color: white;
		background-size: 40%;
		background-repeat: no-repeat;
		width: 30px;
		border-radius: 15px;
		background-position: 45% center;
	}
	background-position: center;
	background-size: cover;
	transform: rotate(-180deg);
	cursor: pointer;
	//margin-right: 40px;
	//@media (max-width: 1280px) { margin-right: 20px; };
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	position: absolute;
	right: 10px;
	@media (min-width: 660px) { right: 20px; }
	z-index: 20;
	
	${props => props.$isHidden && `opacity: 0; visibility: hidden;`}
`;

const Close = styled.div`
	position: absolute;
	z-index: 1002;
	width: 30px;
	height: 30px;
	background-image: url(${close});
	background-position: center;
	background-size: cover;
	top: 40px;
	right: 40px;
	cursor: pointer;
	@media (max-width: 1280px) {
		top: 20px;
		right: 20px;
	}
	
	@media (max-width: 979px) { display: none; }
`;

const SlidesContainer = styled.div`
	overflow: hidden;
	height: calc(100% - 220px);
	@media (max-width: 1280px) { height: calc(100% - 140px); }

	
	@media (max-width: 539px) {
		width: 280px !important;
	}
`;

const Slides = styled.div`
	display: flex;
	flex-direction: row;
	transition: all .2s ease-in-out;
	
	transform: translate(${props => -1 * props.$width * props.$index}px);
	@media (min-width: 540px) { transform: translate(${props => -460 * props.$index}px); }
	@media (min-width: 660px) { transform: translate(${props => -620 * props.$index}px); }
	@media (min-width: 980px) { transform: translate(${props => -940 * props.$index}px); }
	@media (min-width: 1280px) { transform: translate(${props => -1160 * props.$index}px); }
	@media (min-width: 1600px) { transform: translate(${props => -1400 * props.$index}px); }

	align-items: center;
	height: 100% !important;
`;

const Slide = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	
	img {
		max-width: 100%;
		max-height: 100%;
	}
	
	@media (max-width: 539px) {
		width: 280px !important;
	}
`;

const NoPhoto = styled.div`
	width: 100%;
	@media (max-width: 979px) { width: calc(100% - 40px); }
	background: radial-gradient(50% 50% at 50% 50%, #0270CB 0%, #034781 100%);
	height: 200px;
	@media (min-width: 540px) { height: 400px; }
`;

const BehLogo = styled.div`
	width: 81px;
	height: 50px;
	background-image: url(${behLogo});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 81px 50px;
	position: absolute;
	bottom: 10px;
	right: 0;
	
	&.mobile {
		position: relative !important;
		margin-top: 30px;
		margin-left: 20px;
		align-self: flex-start;
		@media (min-width: 980px) { display: none !important; }
	}
`;

const icons = {
	'84': 'dvd-some.svg',
	'85': 'dvd-all.svg',
	'92': 'dvd-req.svg',
	'15': 'wifi-bus.svg',
	'59': 'wifi-some.svg',
	'66': 'wifi-pub.svg',
	'60': 'wifi-all.svg',
	'18': 'tickets.svg',
	'100': 'eng-speak.svg',
	'20': 'farm.svg',
	'17': 'car-rent.svg',
	'69': 'atm.svg',
	'10': 'bar.svg',
	'27': 'pool_in.svg',
	'26': 'pool-out.svg',
	'34': 'billiards.svg',
	'35': 'bowling.svg',
	'78': 'bath-some.svg',
	'79': 'bath-all.svg',
	'16': 'taxi.svg',
	'96': 'hypo-some.svg',
	'97': 'hypo-all.svg',
	'88': 'hypo-req.svg',
	'80': 'shower-some.svg',
	'81': 'shower-all.svg',
	'44': 'change-some.svg',
	'43': 'change-all.svg',
	'37': 'casino.svg',
	'103': 'lockers.svg',
	'47': 'aircond-some.svg',
	'48': 'aircond-all.svg',
	'46': 'roomserv-24.svg',
	'14': 'business-24.svg',
	'98': 'kithen-some.svg',
	'99': 'kithen-all.svg',
	'8': 'elevator.svg',
	'93': 'massage.svg',
	'53': 'minibar-some.svg',
	'54': 'minibar-all.svg',
	'40': 'disabled.svg',
	'39': 'nosmoking.svg',
	'41': 'connecting.svg',
	'45': 'roomserv-9-6.svg',
	'13': 'business-9-6.svg',
	'104': 'business.svg',
	'3': 'parking.svg',
	'2': 'parking-pay.svg',
	'63': 'tv-pay-some.svg',
	'64': 'tv-pay-all.svg',
	'33': 'beach-2.svg',
	'4': 'luggage.svg',
	'74': 'floor-some.svg',
	'75': 'floor-all.svg',
	'7': 'exchange.svg',
	'42': 'pets.svg',
	'9': 'restaurant.svg',
	'24': 'beauty-salon.svg',
	'25': 'sauna.svg',
	'49': 'safe-some.svg',
	'50': 'safe-all.svg',
	'6': 'safe-reception.svg',
	'70': 'spa.svg',
	'21': 'gift-shop.svg',
	'94': 'slippers-some.svg',
	'95': 'slippers-all.svg',
	'87': 'slippers-req.svg',
	'51': 'phone-some.svg',
	'52': 'phone-all.svg',
	'31': 'tennis-in.svg',
	'30': 'tennis-out.svg',
	'101': 'transfer.svg',
	'23': 'gym.svg',
	'102': 'facilities.svg',
	'19': 'babysitter.svg',
	'22': 'newspaper.svg',
	'11': 'laundray.svg',
	'12': 'dryclean.svg',
	'57': 'hypo-some.svg',
	'58': 'iron-all.svg',
	'90': 'iron-req.svg',
	'55': 'dryer-some.svg',
	'56': 'dryer-all.svg',
	'89': 'dryer-req.svg',
	'76': 'bathrobe-some.svg',
	'77': 'bathrobe-all.svg',
	'86': 'bathrobe-req.svg',
	'82': 'kettle-some.svg',
	'83': 'kettle-all.svg',
	'91': 'kettle-req.svg',
	'72': 'el-lock-some.svg',
	'73': 'el-lock-all.svg',
};

const AddressBlock = styled.div`
	> p:nth-child(n + 2) {
		margin-top: 10px;
	}
`;

const Hotel = observer(props => {
	const { t } = useTranslation();
	const { hotelStore } = props;
	const [ isShow, setIsShow ] = useState(false);
	const [ width, setWidth ] = useState(0);
	const SlidesContainerRef = useRef();
	const mapRef = useRef(null);
	const [ current, setCurrent ] = useState(0);
	const mapContainerRef = useRef(null);
	const params = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
		hotelStore.fetch(params.id.replace(/^.*-(\d+)$/, '$1'));
		return hotelStore.clear;
	}, []);

	useEffect(() => {
		if (SlidesContainerRef.current !== undefined && SlidesContainerRef.current !== null) setWidth(SlidesContainerRef.current.getBoundingClientRect().width);
	}, [SlidesContainerRef.current]);

	const [ isAllAmenities, setIsAllAmenities ] = useState(false);

	if (hotelStore.hotel.Code === undefined) {
		return null;
	}

	const hotel = hotelStore.hotel;

	const handlePrev = () => {
		if (current > 0) {
			setCurrent(current - 1);
		}
	};

	const handleNext = () => {
		if (current < hotel.Images.Image.length - 1) {
			setCurrent(current + 1);
		}
	};

	const handleClose = () => setIsShow(false);

	let address = [];
	hotel.Country.Name !== '' && address.push(hotel.Country.Name);
	hotel.Zip !== '' && address.push(hotel.Zip);
	hotel.AdmUnit1.Code !== 1 && address.push(hotel.AdmUnit1.Name);
	hotel.City.Name !== '' && address.push(`${hotel.TypeOfPlace.Name} ${hotel.City.Name}`);
	hotel.Address !== '' && address.push(hotel.Address);

	const handleMapLoaded = () => {};

	const handleMapChange = map => {
		hotelStore.fetchObjects(map.bounds.ne.lat, map.bounds.ne.lng, map.bounds.sw.lat, map.bounds.sw.lng, map.zoom);
	};

	const handleClickToMap = () => {
		try {
			mapContainerRef.current.scrollIntoView({block: 'center', behavior: 'smooth'});
		} catch (error) {
			mapContainerRef.current.scrollIntoView(false);
		}
	};

	const showPhotoBlock = hotel.Images.Image.length > 0 ||
		(hotel.NumberOfRooms !== '0' && hotel.NumberOfRooms !== '') ||
		(hotel.Built !== '0' && hotel.Built !== '') ||
		(hotel.NumberOfFloors !== '0' && hotel.NumberOfFloors !== '') ||
		(hotel.Reconstructed !== '0' && hotel.Reconstructed !== '');

	return (
		<React.Fragment>
			<div className='col-1 padding-80 flex flip980 justify-between pt-sans font-18 align-start'>
				<AddressBlock className={classNames({'margin-bottom-20': showPhotoBlock})}>
					<p className='color-blue font-bold'>
						{t('Адрес')}: {address.join(', ')}
					</p>
					{hotel.Stars.OfficialCertificate.Certificate.Code === 99 && <p className='color-grey'>
						{t('Официальная категория')}: {hotel.Stars.Value}
					</p>}
					{hotel.Stars.OfficialCertificate.Certificate.Code !== 99 && <p className='color-grey'>
						{t('Официальная категория')}: {hotel.Stars.OfficialCertificate.Certificate.Name}, действует до {hotel.Stars.OfficialCertificate.Certificate.ValidBefore}
					</p>}
					{hotel.CityCentre.trim() !== '' &&<p className='color-grey'>
						{t('Расстояние до центра')}: {hotel.CityCentre.replace(/^.* ([\d,]+ км)$/g, '$1')}
					</p>}
				</AddressBlock>

				<div className='button small full-320' onClick={handleClickToMap}>{t('Что находится рядом')}?</div>
			</div>

			{showPhotoBlock ? <div className='bg-lightblue padding-40 fullwidth flex flip980 align-center justify-center'>
				{hotel.Images.Image.length > 0 ? <GalleryContainer $isShow={isShow} className=''>
					<Prev onClick={handlePrev} $isHidden={current === 0}/>

					<SlidesContainer className='col-1 full-320 center-margin' ref={SlidesContainerRef}>
						<Slides $index={current} $width={width}>
							{hotel.Images.Image.map((i, key) => <Slide className='col-1 full-320' key={key}><img
								src={(i.BigUrl || i.Url).replace('test-images', 'images')} alt=""/></Slide>)}
						</Slides>
					</SlidesContainer>

					<Next onClick={handleNext} $isHidden={current === (hotel.Images.Image.length - 1)}/>

					<Close onClick={handleClose}/>
				</GalleryContainer> :
					<NoPhoto className='font-14 color-white flex column align-center justify-center text-uppercase show-980 margin-bottom-40'>
						<img src={pictoNoPhoto} alt='' className='margin-bottom-10'/>
						{t('Нет фото')}
					</NoPhoto>
				}

				<div className='show-980 flex row wrap justify-between align-start' style={{width: 'calc(100% - 40px)'}}>
					<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Количество номеров')}: {hotel.NumberOfRooms}</InfoBlock>
					<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Год постройки')}: {hotel.Built}</InfoBlock>
					<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Количество этажей')}: {hotel.NumberOfFloors}</InfoBlock>
					<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Год реконструкции')}: {hotel.Reconstructed}</InfoBlock>
				</div>

				{hotel.BestEasternHotelsMember.Code === 1 && <BehLogo className='mobile' />}

				<div className='col-1 flex flip980 justify-between align-start hide-980 relative'>
					<div className='col-2 margin-bottom-10'>
						{hotel.Images.Image[0] !== undefined && <BigImage src={(hotel.Images.Image[0].BigUrl || hotel.Images.Image[0].Url).replace('test-images', 'images')} alt='' className='fullwidth' onClick={() => { setCurrent(0); setIsShow(true); }} />}
						{hotel.Images.Image[0] === undefined &&
							<NoPhoto className='font-14 color-white flex column align-center justify-center text-uppercase'>
								<img src={pictoNoPhoto} alt='' className='margin-bottom-10' />
								{t('Нет фото')}
							</NoPhoto>
						}
					</div>

					<div className='col-2'>
						{hotel.Images.Image.length > 1 && <div className='margin-bottom-40 flex row'>
							{hotel.Images.Image.slice(1, 5).map((i, key) => <Thumb key={key} src={(i.Url).replace('test-images', 'images')} alt='' onClick={() => { setCurrent(key + 1); setIsShow(true); }} />)}
							{hotel.Images.Image.length === 6 && <Thumb src={hotel.Images.Image[5].Url.replace('test-images', 'images')} alt='' onClick={() => { setCurrent(6); setIsShow(true); }} />}
							{hotel.Images.Image.length > 6 &&
								<MoreImages className='flex row align-center justify-center' onClick={() => { setCurrent(6); setIsShow(true); }}>
									<Thumb src={hotel.Images.Image[5].Url.replace('test-images', 'images')} alt='' />
									<div className='pt-sans color-white font-18 font-bold'>+{hotel.Images.Image.length - 6}</div>
								</MoreImages>
							}
						</div>}

						<div className='col-2 flex row wrap justify-between align-start'>
							<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Количество номеров')}: {hotel.NumberOfRooms}</InfoBlock>
							<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Год постройки')}: {hotel.Built}</InfoBlock>
							<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Количество этажей')}: {hotel.NumberOfFloors}</InfoBlock>
							<InfoBlock className='color-grey pt-sans font-16 col-4 margin-bottom-20'>{t('Год реконструкции')}: {hotel.Reconstructed}</InfoBlock>
						</div>
					</div>

					{hotel.BestEasternHotelsMember.Code === 1 && <BehLogo />}
				</div>
			</div> : <div className='divider col-1' />}

			{hotel.Story !== undefined && <p className='col-1 padding-80 pt-sans font-20'>{hotel.Story}</p>}

			{hotel.Story !== undefined && <div className='divider col-1' />}

			{hotel.Amenities.Amenity.length > 0 ? <div className='col-1 padding-80'>
				<div className='col-1 flex row wrap justify-between color-blue pt-sans font-16 margin-bottom-80'>
					{hotel.Amenities.Amenity.slice(0, isAllAmenities ? hotel.Amenities.Amenity.length : 10).map((i, key) =>
						<Amenity key={key} className='col-2 margin-bottom-10 flex row align-center'>
							<img src={`/images/services/${icons[i.Code.toString()]}`} alt={i.Name} />
							{i.Name}
						</Amenity>
					)}
				</div>

				<div className='button small col-3 center-margin full-320' onClick={() => setIsAllAmenities(!isAllAmenities)}>{isAllAmenities ? t('Свернуть') : t('Все услуги')}</div>
			</div> : <div className='margin-bottom-80' />}

			<MapContainer className='col-1 margin-bottom-40' ref={mapContainerRef}>
				<GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyBoO5bO7h87PFrFiV2mejukKsWNWv71pj0 ' }}
					defaultCenter={{ lat: hotel.Position.Latitude, lng: hotel.Position.Longitude }}
					defaultZoom={hotel.Position.OptimalGmapZoom}
					ref={mapRef}
					onGoogleApiLoaded={handleMapLoaded}
					onChange={handleMapChange}
					yesIWantToUseGoogleMapApiInternals={true}
				>
					{hotelStore.markers.map((i, key) =>
						<MyMarker key={key} $iconUrl={i.icon} lat={i.Lat} lng={i.Lng}>
							<div>
								<div className='color-blue pt-sans font-14 font-bold'>{i.Title} {i.stars && <img src={i.stars} alt='' />}</div>
								{i.Address && <div className='color-grey pt-sans font-12'>{i.Address}</div>}
							</div>
						</MyMarker>
					)}
				</GoogleMapReact>
			</MapContainer>

			<div className='col-1 flex flip660 align-start justify-between margin-bottom-80'>
				{hotel.Description.trim() !== '' && <div className='col-2'>
					<p className='color-blue font-24 font-bold margin-bottom-10'>{t('Достопримечательности')}:</p>
					{hotel.Description.split(', ').map((i, key) => <p key={key} className='color-grey font-16 pt-sans margin-bottom-10'>{i}</p>)}
				</div>}

				{(hotel.Airport || hotel.RailwayStation || hotel.RiverPort) && <div className='col-2'>
					<p className='color-blue font-24 font-bold margin-bottom-10'>{t('Транспорт')}:</p>
					{hotel.Airport && hotel.Airport.split(', ').map((i, key) => <p key={key} className='color-grey font-16 pt-sans margin-bottom-10'>{t('Аэропорт')} {i}</p>)}
					{hotel.RailwayStation && hotel.RailwayStation.split(', ').map((i, key) => <p key={key} className='color-grey font-16 pt-sans margin-bottom-10'>{t('Ж/д вокзал')} {i}</p>)}
					{hotel.RiverPort && hotel.RiverPort.split(', ').map((i, key) => <p key={key} className='color-grey font-16 pt-sans margin-bottom-10'>{i}</p>)}
				</div>}
			</div>

			<div className='divider col-1 margin-bottom-40' />

			<div className='col-1 margin-bottom-80 pt-sans font-14 color-grey'>
				<p className='margin-bottom-10'>* {t('Набор предоставляемых услуг может меняться гостиницей без предварительного уведомления.')}</p>
				{hotel.SpecialRequirementList.SpecialRequirement.map((i, key) => <p key={key} className='margin-bottom-10'>{i.Text}</p>)}
				<p className='margin-bottom-10'>{t('Любая информация о ранге или категории звездности гостиницы приводится исключительно в целях ознакомления и для общего представления об уровне гостиницы по совокупным мировым меркам, и не предполагает абсолютного совпадения с государственной системой классификации объектов туристской индустрии. «АКАДЕМСЕРВИС» не несет ответственность, если указанные сведения в каком-либо отношении окажутся неверными и/или неполными.')}</p>
			</div>

			<CityHotels {...props} countryId={parseInt(hotel.Country.Code)} cityId={parseInt(hotel.City.Code)} hideIfSingle />
		</React.Fragment>
	);
});

export default Hotel;
