import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useParams} from 'react-router-dom';
import { Colors } from 'constants';
import {useNewsStore} from 'store/useNewsStore';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const MoreNews = styled(Link)`
	height: 30px;
	line-height: 30px !important;

	@media (min-width: 980px) {
		height: 40px;
		line-height: 40px !important;
	}
`;

const NewsBody = styled.div`
	margin-bottom: 40px;
	@media (min-width: 980px) { margin-bottom: 80px; }
	
	img {
		max-width: 100% !important;
	}
	
	> * {
		font-size: 18px;
		line-height: 23px;
		@media (min-width: 980px) {
			font-size: 20px;
			line-height: 26px;
		}
		color: ${Colors.grey};
		width: 100%;
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 620px; }
		@media (min-width: 980px) { width: 940px; }
		@media (min-width: 1280px) { width: 960px; }
		@media (min-width: 1600px) { width: 920px; }
		
		&:not(:first-child) {
			margin-top: 10px;
			@media (min-width: 980px) { margin-top: 20px; }
		}
		font-family: 'PT Sans', sans-serif !important;
		
		* {
			font-family: 'PT Sans', sans-serif !important;
		}
	}

	ul {
		margin-top: 0 !important;
		list-style-type: disc;
		li {
			margin-left: 30px;
		}
	}

	a, b { font-weight: 700; }
	
	a {
		color: ${Colors.blue} !important;
		text-decoration: underline !important;
	}
`;

const News = () => {
	const { t } = useTranslation();
	const { items } = useNewsStore();
	const params = useParams();
	const id = Number(params?.id);
	const locale = i18n.language;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	const item = items.find(i => i.id === id);

	if (item === undefined) {
		return null;
	}

	const {
		title = false,
		enTitle = false,
		enBody = false,
		body = false,
	} = item;

	const newsBody = (locale !== 'ru' && !!enBody) ? enBody : body;
	console.log(newsBody);

	return (
		<div className='col-1 padding-80 flex column align-center'>
			{item.bigImg && <img src={item.bigImg} alt='' className='text-block-full margin-bottom-20' />}

			<p className='font-18 color-grey text-block-full margin-bottom-20 pt-sans font-bold'>
				{(locale !== 'ru' && !!enTitle) ? enTitle : title}
			</p>
			<div className="divider text-block-full margin-bottom-40" />

			<NewsBody dangerouslySetInnerHTML={{__html: newsBody.join('')}} />

			<div className='divider fullwidth margin-bottom-80' />

			<MoreNews to='/news' className='col-3-2 full-320 color-blue hover font-16 border radius flex align-center justify-center text-uppercase'>{t('Другие новости')}</MoreNews>
		</div>
	);
};

export default News;
