import React from 'react';
import styled from 'styled-components';
import {FullWidth} from './Media';

const WhiteLineDiv = styled.div`
	height: 1px;
	background-color: white;
	margin-bottom: 40px;
	${FullWidth}
`;

export const WhiteLine = () => <WhiteLineDiv />;