import React, { useEffect, lazy } from 'react';
import { Banner, Footer, Filter, Header, Loader, Menu, Policy } from 'components/';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
	About,
	AddHotel,
	Agent,
	City,
	Contacts,
	Country,
	Error404,
	Hotel,
	Countries,
	Main,
	News,
	NewsList,
	Service,
	Services,
	Tech,
	Techs,
	Legaldocs,
	// LoginAgent, LoginHotel, LoginInner,
	Error
} from 'containers/';
import {createGlobalStyle} from 'styled-components';
import { Colors } from 'constants';
import checkboxOff from 'images/checkbox_off.svg';
import checkboxOn from 'images/checkbox_on.svg';
import {observer} from 'mobx-react-lite';
import {useMainStore} from 'store/useMainStore';
import {useCountriesStore} from 'store/useCountriesStore';
import {useCountryStore} from 'store/useCountryStore';
import {useCityStore} from 'store/useCityStore';
import {useHotelStore} from 'store/useHotelStore';
import {useSearchStore} from 'store/useSearchStore';
import close from 'images/close.svg';
import {useCheckHotelStore} from 'store/useCheckHotelStore';
import {MainStoreContext} from 'store/MainStoreContext';
import { useMaintainance } from 'hooks';
const Maintainance = lazy(() => import('containers/Maintainance'));

const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
		font-family: 'Rubik', sans-serif !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	
		transition: color .2s ease-in-out, background-color .2s ease-in-out, opacity .2s ease-in-out, visibility .2s ease-in-out;
	}
	
	a {
		text-decoration: none;
		transition: color .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out;
	}
	
	html {
		min-width: 320px;
		width: auto;
	}
	
	body {
		background-color: white;
		min-width: 320px !important;
		width: auto;
		min-height: 100vh;
		height: auto;
	}
	
	#root {
		min-height: 100vh;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.margin-top {
		margin-top: 40px;
		@media (min-width: 980px) { margin-top: 80px; }
	}

	.margin-bottom {
		margin-bottom: 40px;
		@media (min-width: 980px) { margin-bottom: 80px; }
	}

	.padding-top {
		padding-top: 40px;
		@media (min-width: 980px) { padding-top: 80px; }
	}

	.padding-bottom {
		padding-bottom: 40px;
		@media (min-width: 980px) { padding-bottom: 80px; }
	}

	.col-1 {
		width: calc(100% - 40px);
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 620px; }
		@media (min-width: 980px) { width: 940px; }
		@media (min-width: 1280px) { width: 1160px; }
		@media (min-width: 1600px) { width: 1400px; }
		flex-shrink: 0;
		flex-grow: 0;
	}

	.col-2 {
		width: 100%;
		@media (min-width: 540px) and (max-width: 659px) { width: 460px; }
		@media (min-width: 660px) and (max-width: 979px) { width: 620px; }
		@media (min-width: 980px) and (max-width: 1279px) { width: 460px; }
		@media (min-width: 1280px) and (max-width: 1599px) { width: 560px; }
		@media (min-width: 1600px) { width: 680px; }
	}

	.col-3 {
		width: 280px;
		&.square { height: 280px; }
		@media (min-width: 540px) {
			width: 460px;
			&.square { height: 460px; }
		}
		@media (min-width: 660px) {
			width: 620px;
			&.square { height: 620px; }
		}
		@media (min-width: 980px) {
			width: 300px;
			&.square { height: 300px; }
		}
		@media (min-width: 1280px) {
			width: 360px;
			&.square { height: 360px; }
		}
		@media (min-width: 1600px) {
			width: 440px;
			&.square { height: 440px; }
		}
	}
	
	.col-3-2 {
		flex-shrink: 0 !important;
		flex-grow: 0 !important;
		width: 280px;
		&.square { height: 280px; }
		@media (min-width: 540px) {
			width: 460px;
			&.square { height: 460px; }
		}
		@media (min-width: 660px) {
			width: 300px;
			&.square { height: 300px; }
		}
		@media (min-width: 980px) {
			width: 300px;
			&.square { height: 300px; }
		}
		@media (min-width: 1280px) {
			width: 360px;
			&.square { height: 360px; }
		}
		@media (min-width: 1600px) {
			width: 440px;
			&.square { height: 440px; }
		}
	}

	.col-4 {
		width: 100%;
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 300px; }
		@media (min-width: 980px) { width: 220px; }
		@media (min-width: 1280px) { width: 260px; }
		@media (min-width: 1600px) { width: 320px; }
	}
	
	.text-block {
		width: calc(100% - 40px);
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 620px; }
		@media (min-width: 980px) { width: 940px; }
		@media (min-width: 1280px) { width: 960px; }
		@media (min-width: 1600px) { width: 920px; }
	}
	
	.text-block-full {
		width: 100%;
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 620px; }
		@media (min-width: 980px) { width: 940px; }
		@media (min-width: 1280px) { width: 960px; }
		@media (min-width: 1600px) { width: 920px; }
	}
	
	.full-320 {
		@media (max-width: 539px) { width: 100% !important; }
	}
	
	.center-text { text-align: center; }
	
	.center-margin {
		margin-left: auto;
		margin-right: auto;
	}
	
	.text-blue { color: #034781; }
	.text-white { color: white; }
	.text-bold { font-weight: 500; }
	
	.pictogram {
		width: 140px !important;
		height: 140px !important;
		object-fit: contain;
		object-position: top;
		flex-shrink: 0;
		flex-grow: 0;
		@media (min-width: 1280px) and (min-width: 1599px) {
			width: 160px !important;
			height: 160px !important;
		}
		@media (min-width: 1600px) {
			width: 200px !important;
			height: 200px !important;
		}
	}
	
	.block-fullwidth {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.padding-20 { 
		padding: 20px 0;
	}
	
	.padding-40 { 
		padding: 20px 0;
		@media (min-width: 980px) { padding: 40px 0; }
	}
	
	.padding-80 { 
		padding: 40px 0;
		@media (min-width: 980px) { padding: 80px 0; }
	}
	
	.bg-blue { background-color: ${Colors.blue}; }
	.bg-lightblue { background-color: #D3EFFF; }
	.bg-white { background-color: ${Colors.white} !important; }
	.bg-red { background-color: #AA1F00; }
	.bg-green { background-color: #6DA544; }

	.color-grey {
		color: ${Colors.grey};
		* { color: ${Colors.grey}; } 
	}
	
	.color-lightgrey {
		color: ${Colors.lightgrey};
		* { color: ${Colors.lightgrey}; } 
	}
	
	.color-blue {
		color: ${Colors.blue};
		* { color: ${Colors.blue}; }
		
		&.hover:hover, &.active {
			color: ${Colors.lightblue};
			* { color: ${Colors.lightblue}; }
		}
	}
	
	.color-white {
		color: ${Colors.white};
		* { color: ${Colors.white}; } 
	}
	
	.color-red {
		color: #AA1F00;
	}

	.pt-sans { font-family: 'PT Sans', sans-serif !important; }
	
	.font-12 {
		font-size: 12px;
		line-height: 15px;
	}
	
	.font-14 {
		font-size: 14px;
		line-height: 17px;
	}
	
	.font-16 {
		font-size: 14px;
		line-height: 18px;
		@media (min-width: 980px) {
			font-size: 16px;
			line-height: 21px;
		}
	}
	
	.font-18 {
		font-size: 16px;
		line-height: 21px;
		@media (min-width: 980px) {
			font-size: 18px;
			line-height: 23px;
		}
	}
	
	.font-20 {
		font-size: 18px;
		line-height: 23px;
		@media (min-width: 980px) {
			font-size: 20px;
			line-height: 26px;
		}
	}
	
	.font-24 {
		font-size: 20px;
		line-height: 24px;
		@media (min-width: 980px) {
			font-size: 24px;
			line-height: 28px;
		}
	}
	
	.font-34 {
		font-size: 28px;
		line-height: 33px;
		font-weight: 500;
		@media (min-width: 980px) {
			font-size: 34px;
			line-height: 40px;
		}
	}
	
	.font-42 {
		font-size: 32px;
		line-height: 38px;
		font-weight: 500;
		@media (min-width: 980px) {
			font-size: 42px;
			line-height: 50px;
		}
	}
	
	.font-48 {
		font-size: 32px;
		line-height: 38px;
		font-weight: 500;
		@media (min-width: 980px) {
			font-size: 48px;
			line-height: 57px;
		}
	}
	
	.font-72 {
		font-size: 64px;
		line-height: 76px;
		font-weight: 500;
		@media (min-width: 980px) {
			font-size: 72px;
			line-height: 85px;
		}
	}
	
	.font-bold {
		font-weight: 500;
		&.pt-sans {
			font-weight: 700;
		}
	}
	
	.text-center { text-align: center; }
	.text-right { text-align: right; }
	.text-uppercase { text-transform: uppercase; }
	.test-border { border: 1px solid red; }
	
	.line { 
		// width: 100%;
		height: 1px;
		margin-bottom: 20px;
		@media (min-width: 980px) { margin-bottom: 40px; }
	}
	
	.margin-bottom-10 { margin-bottom: 10px; }
	.margin-bottom-20 {
		margin-bottom: 10px;
		@media (min-width: 980px) { margin-bottom: 20px; }
	}
	.margin-bottom-40 {
		margin-bottom: 20px;
		@media (min-width: 980px) { margin-bottom: 40px; }
	}
	.margin-bottom-60 {
		margin-bottom: 20px;
		@media (min-width: 980px) { margin-bottom: 60px; }
	}
	.margin-bottom-80 {
		margin-bottom: 40px;
		@media (min-width: 980px) { margin-bottom: 80px; }
	}
	
	.flex {
		display: flex;
		
		&.column { flex-direction: column; }
		&.row { flex-direction: row; }
		&.justify-center { justify-content: center; }
		&.justify-between { justify-content: space-between; }
		&.align-center { align-items: center; }
		&.align-stretch { align-items: stretch; }
		&.align-start { align-items: flex-start; }
		&.wrap { flex-wrap: wrap; }
		&.grow { flex-grow: 1; }
		&.flip980 {
			flex-direction: column;
			@media (min-width: 980px) { flex-direction: row; }
		}
		&.flip660 {
			flex-direction: column;
			@media (min-width: 660px) { flex-direction: row; }
		}
	}
	
	.mobile-hidden {
		@media (max-width: 979px) { display: none; }
	}
	
	.button-white {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		border: 2px solid ${Colors.blue};
		color: ${Colors.blue};
		box-sizing: border-box;
		border-radius: 5px;
		text-transform: uppercase;
		background-color: ${Colors.white};
		
		@media (min-width: 980px) {
			height: 40px;
		}
		
		&:hover {
			color: ${Colors.white};
			background-color: ${Colors.lightblue};
			border: 2px solid ${Colors.lightblue};
		}
	}
	
	.adv-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		@media (min-width: 980px) {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
		}
		
		a {
			width: calc(100% - 40px);
			@media (min-width: 540px) { width: 460px; }
			@media (min-width: 660px) { width: 620px; }
			@media (min-width: 980px) { width: 300px; }
			@media (min-width: 1280px) { width: 360px; }
			@media (min-width: 1600px) { width: 440px; }
			display: flex;
			flex-direction: column;
			align-items: center;
			@media (max-width: 979px) {
				&:nth-child(n+2) { margin-top: 20px; }
			}
			
			p { width: 100%; }

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				
				img {
					width: 85%;
					transition: width .2s ease-in-out, height .2s ease-in-out;
				}
			}
			
			&:hover {
				p.color-blue {
					color: ${Colors.lightblue};
				}
				
				div {
					img {
						width: 100%;
					}
				}			
			}
		}
	}
	
	.divider {
		height: 1px;
		background-color: ${Colors.blue};
	}
	
	.lightblue-container {
		background-color: #D3EFFF;
		width: 100%;
	
		div.item {
			> img {
				flex-grow: 0;
				flex-shrink: 0;
				object-fit: contain;
				object-position: top;
				display: flex;
				@media (max-width: 979px) { display: none !important; }
			}
			
			div {
				width: 100%;
				@media (min-width: 540px) { width: 460px; }
				@media (min-width: 660px) { width: 620px; }
				@media (min-width: 980px) { width: 620px; }
				@media (min-width: 1280px) { width: 760px; }
				@media (min-width: 1600px) { width: 920px; }
				@media (max-width: 539px) {
					//padding-left: 20px;
					//padding-right: 20px;
				}
				
				> img {
					margin-bottom: 20px;
					@media (min-width: 980px) { display: none; }
				}
			}
		}
	}
	
	.border {
		border: 2px solid ${Colors.blue};
		&.radius { border-radius: 5px; }
	}
	
	.text-underline { text-decoration: underline; }
	
	/*
	iframe[src*=youtube]
	{
		width: 100% !important;
		max-height: 100% !important;
		height: auto !important;
	}
	
	 */
	 
	.video-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	
	.button {
		border: 2px solid ${Colors.blue};
		background-color: white;
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 20px;
		color: ${Colors.blue};
		font-size: 20px;
		font-weight: 500;
		text-transform: uppercase;
		transition: color .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out;
		cursor: pointer;
		
		&.small {
			font-size: 14px;
			font-weight: normal;
		}
		
		img {
			margin-right: 10px;
			width: 60px;
			height: 60px;
			object-fit: contain;
			object-position: center;
		}
		
		@media (min-width: 980px) {
			font-size: 24px;
			&.small {
				font-size: 16px;
			}
		}
		
		&:hover {
			background-color: #0270CB;
			border: 2px solid #0270CB;
			color: white;
		}
	}
	
	.fullwidth { width: 100%; }

	.width-picto {
		width: 140px;
		@media (min-width: 1280px) { width: 160px; } 
		@media (min-width: 1600px) { width: 200px; }
		object-fit: cover;
	}

	input[type="radio"], input[type="checkbox"] {
		display: none;
		
		& + label {
			display: flex;
			position: relative;
			font-size: 14px;
			line-height: 20px;
			color: #333333;
			
			@media (min-width: 980px) { font-size: 16px; }
			
			&.small_margin {
				margin-bottom: 10px !important;
			}
			
			&:before {
				content: '';
				width: 20px;
				height: 20px;
				display: flex;
				background-image: url(${checkboxOff});
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				margin-right: 10px;
			}
		}
		
		&:checked + label:before {
			background-image: url(${checkboxOn});
		}
	}
	
	select {
		display: flex;
	    width: 100%;
	    height: 40px;
	    border: 1px solid ${Colors.blue};
	    margin-bottom: 20px;
	    color: ${Colors.blue};
	    padding: 0 10px;	
	    font-size: 16px;
	}
	
	input:focus, textarea:focus, select:focus {
		outline: none !important;
	}
	
	textarea {
		width: 100%;
		height: 250px;
		resize: none;
		border: 1px solid ${Colors.blue};
		color: ${Colors.blue};
		font-size: 16px;
		line-height: 19px;
		padding: 10px;
		margin-bottom: 20px;
	}
	
	.border-thin {
		border: 1px solid ${Colors.blue};
	}
	
	.small-input {
		@media (max-width: 539px) { width: 80px !important; }
	}
	
	.spaced {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.text-underline { text-decoration: underline; }
	
	//* { border: 1px solid red !important; }
	.hide-980 {
		@media (max-width: 979px) { display: none !important; }
	}

	.show-980 {
		@media (min-width: 980px) { display: none !important; }
	}
	
	.modal {
		background-color: rgba(3, 71, 129, 0.8);
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		padding-top: 70px;
		@media (min-width: 980px) { padding-top: 120px; }
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		&.show {
			opacity: 1;
			visibility: visible;
		}
		
		div.close {
			position: absolute;
			z-index: 1002;
			width: 30px;
			height: 30px;
			background-image: url(${close});
			background-position: center;
			background-size: cover;
			top: 20px;
			right: 20px;
			cursor: pointer;
			@media (min-width: 1280px) {
				top: 40px;
				right: 40px;
			}
		}
		
		div.button-ok {
			border: 2px solid white;
			background-color: transparent;
			color: white;
			height: 40px;
			border-radius: 5px;
			text-transform: uppercase;
			cursor: pointer;
			transition: color .2s ease-in-out, background-color .2s ease-in-out;
			
			&:hover {
				background-color: white;
			}
		}

		&.success div.button-ok:hover { color: #6DA544; }
		&.error div.button-ok:hover { color: #AA1F00; }
	}
	
	.relative { position: relative; }
`;

const App = observer(() => {
	const location = useLocation();
	const { isWorks, worksDate, worksTimeFrom, worksTimeTo } = useMaintainance();

	const mainStore = useMainStore();
	const stores = {
		mainStore: mainStore,
		countriesStore: useCountriesStore(mainStore),
		countryStore: useCountryStore(mainStore),
		cityStore: useCityStore(mainStore),
		hotelStore: useHotelStore(mainStore),
		searchStore: useSearchStore(mainStore),
		checkHotelStore: useCheckHotelStore(mainStore),
	};

	useEffect(() => window.ym(68673835, "init", {clickmap: true, trackLinks: true, accurateTrackBounce: true}), [location.pathname]);

	if (isWorks === false) return null;

	if (isWorks === 1) return <Maintainance worksDate={worksDate} worksTimeFrom={worksTimeFrom} worksTimeTo={worksTimeTo} />;

	return (
		<MainStoreContext.Provider value={mainStore}>
			<GlobalStyles />
			<Header />
			<Menu />
			{location.pathname === '/' && <Filter {...stores} />}
			{location.pathname !== '/' && /^\/login\//.test(location.pathname) === false && <Banner {...stores} />}
			{mainStore.error === false ?
				<Routes>
					<Route path="/about" element={<About />}/>
					<Route path="/add_hotel" element={<AddHotel {...stores} />}/>
					<Route path="/agent" element={<Agent />}/>
					<Route path="/about" element={<About />}/>
					<Route path="/contacts" element={<Contacts />}/>
					<Route path="/error404" element={<Error404 />}/>
					<Route path="/legaldocs" element={<Legaldocs />}/>

					<Route path="/countries" element={<Countries {...stores} />}/>
					<Route path="/country/:id" element={<Country {...stores} />}/>
					<Route path="/city/:id" element={<City {...stores} />}/>
					<Route path="/hotel/:id" element={<Hotel {...stores} />}/>

					<Route path="/" element={<Main />}/>
					<Route path="/news/:id" element={<News />}/>
					<Route path="/news" element={<NewsList />}/>
					<Route path="/services/:id" element={<Service />}/>
					<Route path="/services" element={<Services />}/>
					<Route path="/techs/:id" element={<Tech />}/>
					<Route path="/techs" element={<Techs />}/>
					<Route path="*" element={<Navigate replace to="/error404" />} />
				</Routes> :
				<Error {...stores} />
			}
			{/*<Filler />*/}
			<Footer />
			<Policy />
			<Loader mainStore={mainStore} />
		</MainStoreContext.Provider>
	);
});

export default App;
