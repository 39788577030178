import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;

	> div {
		p {
			text-align: center;

			&:first-child {
				font-weight: bold;
				font-size: 64px;
				line-height: 76px;
				margin-bottom: 10px;
				@media (min-width: 980px) {
					font-size: 72px;
					line-height: 85px;
					margin-bottom: 20px;
				}
			}

			&:nth-child(2) {
				font-size: 14px;
				line-height: 17px;
				@media (min-width: 980px) {
					font-size: 16px;
					line-height: 19px;
				}
				height: 38px;
				text-transform: uppercase;
			}
		}
	}
`;

export const AdvantagesDigits = ({ duration = 1 }) => {
	const { t } = useTranslation();

	const [ref, inView] = useInView({
		threshold: 0,
	});

	const items = [
		{
			count: 30,
			title: t('лет опыта')
		},
		{
			count: 5133,
			title: t('отеля в России и СНГ')
		},
		{
			count: 17,
			title: t('Способов Интеграции')
		},
		{
			count: 125,
			title: t('сотрудников')
		},
	];

	return (
		<Container className='col-1 center-margin' ref={ref}>
			{items.map((i, key) => (
				<div className='margin-bottom col-4' key={key}>
					<p>
						<CountUp duration={duration} end={i.count} delay={0} separator=' ' useEasing={true} start={0} redraw={true}>
							{({ countUpRef, start }) => {
								if (inView) {
									start();
								}
								return <span ref={countUpRef}/>;
							}}
						</CountUp>
					</p>
					<p>{i.title}</p>
				</div>
			))}
		</Container>
	);
};