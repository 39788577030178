import React, {useState} from 'react';
import styled from 'styled-components';
import {
	// CheckHotel,
	Login,
	SlideShow
} from './';
import {observer} from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const FilterDiv = styled.div`
	position: relative;
`;

const LayerColor = styled.div`
	height: 100%;
	width: 100%;
	position: absolute !important;
	top: 0;
	left: 0;
	background-color: rgba(3, 71, 129, 0.6);
	z-index: 20;
`;

const Container = styled.div`
	z-index: 100;
`;

const Title = styled.div`
	z-index: 100;
`;

const MainText = styled.div`
	line-height: 22px !important;
	@media (min-width: 980px) { line-height: 30px !important; }
	@media (max-width: 979px) { margin-bottom: 40px; }
`;

export const Filter = observer(() => {
	const { t } = useTranslation();
	const [ isShow, setIsShow ] = useState(false);

	return (
		<>
			<FilterDiv className='block-fullwidth padding-80'>
				{/*<LayerImg />*/}
				<SlideShow />
				<LayerColor />

				<Title className='color-white col-1 font-48 font-bold text-uppercase margin-bottom-40'>{t('B2B система')} <br/>{t('Онлайн-бронирования отелей')}</Title>

				<Container className='col-1 flex row wrap justify-between color-white'>
					<MainText className='col-1 font-20'>
						{t('В основе нашей системы — уникальная база данных компании «Академсервис», накопленная за 30 лет успешной работы. Это свыше 5 000 отелей категорий 2-5* в России и ближнем зарубежье, 800+ гостиниц в глобальных системах бронирования и более 200 000 отелей по всему миру.')}
					</MainText>
				</Container>
			</FilterDiv>
			<Login isShow={isShow} setIsShow={setIsShow} />
		</>
	);
});