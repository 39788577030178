import { useLocalObservable } from 'mobx-react-lite';
import {action} from 'mobx';
import i18n from 'i18next';

export const useCityStore = mainStore => useLocalObservable(() => ({
	country: {},
	setCountry: action(function(country) { this.country = country; }),

	city: {},
	setCity: action(function(city) { this.city = city; }),

	request(countryId, cityId) {
		return {
			HotelRequest: {
				UserType: "buyer",
				BuyerId: "RO1BAND",
				UserId: "WWW",
				Password: "84765",
				Language: i18n.language,
				ProfitCentreCode: "",
				SUserCode: "",
				IsSyncOtgr: "",
				ActionPubliclist: {
					Parameters: {
						CountryCode: countryId,
						CityCode: cityId
					}
				}
			}
		};
	},
	requestCityInfo(id) {
		return {
			CityRequest: {
				UserType: "buyer",
				BuyerId: "RO1BAND",
				UserId: "WWW",
				Password: "84765",
				Language: i18n.language,
				ProfitCentreCode: "",
				SUserCode: "",
				IsSyncOtgr: "",
				ActionDescription: {
					Parameters: {
						Code: id
					}
				}
			}
		};
	},
	fetch(countryId, cityId) {
		if (countryId === 0) {
			mainStore.ajax(this.requestCityInfo(cityId), d => {
				mainStore.ajax(this.request(d.CityResponse.City[0].Country.Code, cityId), data => {
					const country = data.HotelResponse.Country;
					let city = country.CityList.City.find(i => i.Code === cityId);
					let hotels = city.HotelList.Hotel.map(i => ({
						Code: i.Code,
						Name: i.Name,
						Ref: i.Ref,
						Stars: parseInt(i.Stars.Value)
					}));
					delete city.HotelList;
					this.setCity({...city, hotels: hotels});
					this.setCountry({
						Code: country.Code,
						Name: country.Name
					});
				});
			});
		} else {
			mainStore.ajax(this.request(countryId, cityId), data => {
				const country = data.HotelResponse.Country;
				let city = country.CityList.City.find(i => i.Code === cityId);
				let hotels = city.HotelList.Hotel.map(i => ({
					Code: i.Code,
					Name: i.Name,
					Ref: i.Ref,
					Stars: parseInt(i.Stars.Value)
				}));
				delete city.HotelList;
				this.setCity({...city, hotels: hotels});
				this.setCountry({
					Code: country.Code,
					Name: country.Name
				});
			});
		}
	},
	clear() {
		this.setCity({});
		this.setCountry({});
	}
}));