import React, {useEffect, useState} from 'react';
import {declines} from 'utils';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	padding-top: 40px;
	padding-bottom: 20px;
	@media (min-width: 980px) {
		padding-top: 80px;
		padding-bottom: 40px;
	}

`;

export const CityHotels = observer(({cityStore, hideIfSingle = false, countryId, cityId}) => {
	const { t } = useTranslation();
	const [ stars, setStars ] = useState([]);

	useEffect(() => {
		cityStore.fetch(countryId, cityId);
		return cityStore.clear;
	}, []);

	const handleChangeStars = (e, index) => {
		e.preventDefault();

		if (index === false) {
			setStars([]);
		} else {
			setStars([index]);
		}
	};

	if (cityStore.city.hotels === undefined) return null;

	const starsList = cityStore.city.hotels
		.reduce((acc, i) => acc.includes(i.Stars) ? acc : acc.concat([i.Stars]), [])
		.sort((a, b) => a < b ? 1 : -1);

	if (hideIfSingle && cityStore.city.hotels.length < 2) return null;
	
	return (
		<>
			<Container className='block-fullwidth bg-lightblue'>
				<p className='margin-bottom-20 color-blue text-uppercase font-42 flex row justify-center align-center text-center col-1'>
					{t('Отели в г.')} {cityStore.city.Name}
				</p>

				{starsList.length > 1 && <div className='col-1 margin-bottom-40 flex row wrap justify-center font-16 text-uppercase'>
					<a href="/" className={stars.length === 0 ? 'color-blue spaced active' : 'color-blue spaced hover'} onClick={e => handleChangeStars(e, false)}>{t('Все')}</a>
					{
						starsList.map((i, key) =>
							<a href="/" className={stars.includes(i) ? 'color-blue spaced active' : 'color-blue spaced hover'} onClick={e => handleChangeStars(e, i)} key={key}>{t('Отели уровня {{i}} из 5', {i})}</a>)
					}
				</div>}

				{starsList.filter(i => stars.length === 0 || stars.includes(i)).map((s, sKey) =>
					<React.Fragment key={sKey}>
						<p className='color-blue text-center font-34 margin-bottom-20'>{t('Отели уровня {{s}} из 5', {s})}</p>
						<p className='color-blue text-center font-24 font-bold margin-bottom-20'>
							{cityStore.city.hotels.filter(i => i.Stars === s).length} {declines(cityStore.city.hotels.filter(i => i.Stars === s).length, [t('отель'), t('отеля'), t('отелей')])} в г. {cityStore.city.Name}
						</p>

						<div className='margin-bottom-40 col-1 flex row wrap justify-between'>
							{cityStore.city.hotels.filter(i => i.Stars === s).map((i, key) =>
								<Link to={`/hotel/${i.Ref}-${i.Code}`} key={key} className='col-3-2 font-20 flex row color-blue hover align-top margin-bottom-10'>
									{i.Name}
								</Link>
							)}
						</div>
					</React.Fragment>
				)}
			</Container>
		</>
	);
});