import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useNewsStore} from 'store/useNewsStore';
import i18n from 'i18next';

const NewsItem = styled(Link)`
	> div {
		position: relative;
		line-height: 0;
		padding-top: 75% !important;
		width: 100%;

		img {
			z-index: 100;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(3, 71, 129, 0.6);
			opacity: 0;
			visibility: hidden;
			transition: all .2s ease-in-out;
			z-index: 20;
		}

		img {
			width: 100%;
			z-index: 10;
		}
	}
	
	&:hover div:before {
		opacity: 1;
		visibility: visible;
	}
`;

/*
const MoreNews = styled(Link)`
	height: 30px;
	@media (min-width: 980px) { height: 40px; }
`;

const Year = styled(Link)`
	color: ${Colors.blue};
	margin: 0 10px;
	
	&:hover, &.current { color: ${Colors.lightblue}; }
`;
 */

const NewsList = () => {
	const { items } = useNewsStore();
	const locale = i18n.language;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className='padding-80 col-1 flex row wrap justify-between'>
				{items.map((i, key) =>
					<NewsItem key={key} to={`/news/${i.id}`} className='col-3-2 margin-bottom-40 color-blue hover full-320'>
						<div className='margin-bottom-20'>
							{locale !== 'ru' && !!i?.enSmallImg && <img src={i.enSmallImg} alt="" />}
							{(locale === 'ru' || !i?.enSmallImg) && <img src={i.smallImg} alt="" />}
						</div>
						<p className='font-16 margin-bottom-10'>{i.date}</p>
						<p className='font-bold font-24'>
							{(locale !== 'ru' && !!i?.enTitle) ? i.enTitle : i.title}
						</p>
					</NewsItem>
				)}
			</div>
		</>
	);
};

export default NewsList;