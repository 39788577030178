import React, {useEffect} from 'react';
import { Advantages } from 'components';
import schemeTechs from 'images/scheme-technology.svg';
import schemeTechsEn from 'images/tech_scheme_eng.svg';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import pictoHotel from 'images/picto_add_hotel.svg';
import pictoAgent from 'images/picto_agent.svg';
import pictoWhere from 'images/picto_where.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const AdvList = styled.div`
	@media (max-width: 539px) { width: 100% !important; }
`;

const Techs = () => {
	const { t } = useTranslation();


	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='text-block pt-sans padding-80 font-20 color-grey'>
				{t('Являясь дистрибутивной платформой, система бронирования acase.ru предлагает обширные возможности по интеграции как для агентов, так и для отелей. Мы уже присутствуем во многих популярных менеджерах каналов, технологических платформах и онлайновых площадках. Также «АКАДЕМ-ОНЛАЙН» всегда открыт для создания нестандартных интеграций по Вашему запросу.')}
			</div>

			<div className='divider col-1 margin-bottom-80' />

			<a href={schemeTechs} target="_blank" className='text-block margin-bottom-80' rel="noreferrer">
				<img src={i18n.language === 'ru' ? schemeTechs : schemeTechsEn} alt="" className='block-fullwidth' />
			</a>

			<div className='divider col-1' />

			<div className='col-1 padding-80'>
				<AdvList className='adv-list col-1'>
					<Link to="/techs/for_hotels" className='col-3'>
						<div className='margin-bottom-20 pictogram'>
							<img src={pictoHotel} alt='' />
						</div>
						<p className='color-blue text-center font-24 margin-bottom-20'>{t('Интеграция для отелей')}</p>
						<p className='pt-sans font-18 text-center color-grey'>{t('Возможность управлять ценами, наличием мест и получать бронирования прямо в системе управления отелем или в менеджере каналов.')}</p>
					</Link>

					<Link to="/techs/for_agents" className='col-3'>
						<div className='margin-bottom-20 pictogram'>
							<img src={pictoAgent} alt='' />
						</div>
						<p className='color-blue text-center font-24 margin-bottom-20'>{t('Интеграция для Агентов')}</p>
						<p className='pt-sans font-18 text-center color-grey'>{t('Возможность продавать весь ассортимент гостиниц «АКАДЕМ-ОНЛАЙН» прямо на своем сайте и в своей корпоративной системе бронирования.')}</p>
					</Link>

					<Link to="/techs/where" className='col-3'>
						<div className='margin-bottom-20 pictogram'>
							<img src={pictoWhere} alt='' />
						</div>
						<p className='color-blue text-center font-24 margin-bottom-20'>{t('Где мы представлены')}</p>
						<p className='pt-sans font-18 text-center color-grey'>{t('Возможность съэкономить время и бюджет, выбрав уже готовое техническое решение.')}</p>
					</Link>
				</AdvList>
			</div>

			<Advantages />

			<div className='block-fullwidth bg-blue'>
				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
};

export default Techs;