import React, {useEffect} from 'react';
import pictoWarning from 'images/picto_warning.svg';
import {observer} from 'mobx-react-lite';

const Error = observer(({mainStore = {}}) => {
	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<div className='col-1 flex column align-center padding-80'>
			<img src={pictoWarning} alt='' className='width-picto margin-bottom-20' />

			{mainStore.error.map((i, key) => {
				return key === 0 ?
					<p className='text-center text-uppercase color-blue font-24 font-bold margin-bottom-20'>{i}</p> :
					<p className='text-center color-blue font-18 font-bold margin-bottom-10'>{i}</p>;

			})}
		</div>
	);
});

export default Error;