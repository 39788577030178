import React from 'react';
import styled from 'styled-components';

const MainTextContainer = styled.p`
	font-family: PT Sans, sans-serif !important;
	font-size: 18px;
	line-height: 23px;
	@media (min-width: 980px) {
		font-size: 20px;
		line-height: 26px;
	}
	@media (max-width: 539px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	color: #333333;
`;

export const MainText = ({children = null}) => (
	<MainTextContainer className='col-1 padding-top padding-bottom center-margin'>
		{children}
	</MainTextContainer>
);