import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {FullWidth, TextWidth} from './Media';

const BigButtonLink = styled(Link)`
	${props => props.$fullWidth ? FullWidth : TextWidth}
	border: 2px solid #034781;
	border-radius: 5px;
	margin: ${props => `${props.$marginTop}px} auto ${props.$marginBottom}px auto`};
	height: 100px;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #034781;
	text-transform: uppercase;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: all .2s ease-in-out;
	
	&:hover {
		background-color: #0270CB;
		border: 2px solid #0270CB;
		color: white;
	}
	
	img {
		height: 60px;
		margin-right: 10px;
	}
`;

export const BigButton = ({children = '', className = '', center = false, fullWidth = false, marginTop = 0, marginBottom = 0, to = '/'}) =>
	<BigButtonLink className={className} $center={center} $fullWidth={fullWidth} $marginTop={marginTop} $marginBottom={marginBottom} to={to}>{children}</BigButtonLink>;