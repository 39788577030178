import React from 'react';
import styled from 'styled-components';

const PageTestDiv = styled.div`
	text-align: center;
	padding: 100px;
	font-size: 48px;
	font-weight: bold;
	color: red;
	text-transform: uppercase;
`;

export const PageTest = ({text = ''}) => (
	<PageTestDiv>
		{text}
	</PageTestDiv>
);