import { useLocalObservable } from 'mobx-react-lite';
import {action} from 'mobx';
import i18n from 'i18next';

export const useCountryStore = mainStore => useLocalObservable(() => ({
	country: {},
	setCountry: action(function(country) { this.country = country; }),

	cities: [],
	setCities: action(function(cities) { this.cities = cities; }),

	regions: [],
	setRegions: action(function(regions) { this.regions = regions; }),

	request(id) {
		return {
			CityRequest: {
				UserType: "buyer",
				BuyerId: "RO1BAND",
				UserId: "WWW",
				Password: "84765",
				Language: i18n.language,
				ProfitCentreCode: "",
				SUserCode: "",
				IsSyncOtgr: "",
				ActionPubliclist: {
					Parameters: {
						CountryCode: id
					}
				}
			}
		};
	},
	fetch(id) {
		mainStore.ajax(this.request(id), data => {
			const country = data.CityResponse.Country.find(i => i.Code === parseInt(id));
			let regions = [];
			country.City.forEach(i => {
				if (regions.findIndex(r => r.Code === i.AdmUnit1.Code) === -1) {
					regions.push({
						Code: i.AdmUnit1.Code,
						Name: i.AdmUnit1.Name
					});
				}
			});

			regions.sort((a, b) => {
				if (a.Name === b.Name) return 0;
				return a.Name > b.Name ? 1 : -1;
			});

			this.setRegions(regions);
			this.setCities(country.City);
			this.setCountry({
				Code: country.Code,
				Name: country.Name
			});
		});
	},
	clear() {
		this.setCountry({});
		this.setCities([]);
		this.setRegions([]);
	}
}));