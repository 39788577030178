import React, {useEffect} from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import {Search} from 'components';
import { useTranslation } from 'react-i18next';

const CountriesDiv = styled.div`
	

	a {
		position: relative;
		padding-left: 35px;		
	
		img {
			position: absolute;
			left: 0;
			width: 30px;
			height: 20px;
			object-fit: contain;
			margin-right: 5px;
		}
	}
`;

const Container = styled.div`
	padding-top: 40px;
	padding-bottom: 20px;
	@media (min-width: 980px) {
		padding-top: 80px;
		padding-bottom: 40px;
	}
`;

const Countries = observer(props => {
	const { t } = useTranslation();
	const { countriesStore } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
		countriesStore.fetch();
		return countriesStore.clear;
	}, []);

	return (
		<>
			<div className='text-block padding-80'>
				<Search {...props} />
			</div>

			<Container className='block-fullwidth bg-lightblue'>
				<p className='font-42 color-blue text-center margin-bottom-20 text-uppercase'>{t('Отели по странам')}</p>

				<CountriesDiv className='col-1 flex row wrap justify-between margin-bottom-40'>
					{countriesStore.countries.map((i, key) =>
						<Link to={`/country/${i.Code}`} key={key} className='col-3-2 font-20 flex row color-blue hover align-center'>
							{i.Name}
						</Link>
					)}
				</CountriesDiv>
			</Container>
		</>
	);
});

export default Countries;