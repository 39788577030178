import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Colors } from 'constants';
import { useTranslation } from 'react-i18next';

const FooterDiv = styled.div`
	ul {
		a {
			padding: 0 10px;
			text-transform: uppercase;
			
			&:hover, &.active { color: ${Colors.lightblue}; }
		}
	}

	> div:last-child {
		display: flex;
		flex-direction: column;
		gap: 5px;

		a {
			text-decoration: underline;
		}
	}
`;

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className='block-fullwidth bg-blue padding-40'>
			<FooterDiv className='col-1 color-white font-16'>
				<ul className='margin-bottom-20 flex row justify-center mobile-hidden'>
					<li><NavLink to='/about'>{t('О нас')}</NavLink></li>
					<li><NavLink to='/news'>{t('Новости')}</NavLink></li>
					<li><NavLink to='/contacts'>{t('Контакты')}</NavLink></li>
					<li><NavLink to='/countries'>{t('Отели')}</NavLink></li>
				</ul>

				<ul className='margin-bottom-20 flex row justify-center mobile-hidden'>
					<li><NavLink to='/services'>{t('Услуги')}</NavLink></li>
					<li><NavLink to='/add_hotel'>{t('Добавить отель')}</NavLink></li>
					<li><NavLink to='/agent'>{t('Стать агентом')}</NavLink></li>
					<li><NavLink to='/techs'>{t('Технологии')}</NavLink></li>
				</ul>

				<ul className='margin-bottom-40 flex row justify-center mobile-hidden'>
					<li><NavLink to='/legaldocs'>{t('Правовые документы')}</NavLink></li>
				</ul>

				<div className='line bg-white mobile-hidden' />

				<div className='pt-sans font-16 text-center'>
					<p>© «{t('АКАДЕМ-ОНЛАЙН')}», 2020</p>
					<p>
						{t('Разработано')} <a href="https://acssoft.ru" target="_blank" rel="noreferrer">&laquo;{t('АКС-СОФТ')}&raquo;</a>
					</p>
				</div>
			</FooterDiv>
		</div>
	);
};