import React, {useEffect} from 'react';
import styled from 'styled-components';
import pictoDoc from 'images/picto-doc.svg';
import pictoDoc2 from 'images/picto_doc_2.svg';
import { useTranslation } from 'react-i18next';

const Docs = styled.div`
	position: relative;
	
	a {
		height: 100px;
		width: 100%;
		@media (min-width: 540px) { width: 460px; }
		@media (min-width: 660px) { width: 300px; }
		@media (min-width: 980px) { width: calc(100% / 2 - 10px); }
		@media (min-width: 1280px) { width: calc(100% / 2 - 20px); }
		
		img {
			margin-right: 26px;
			width: 47px;
			height: 60px;
			object-fit: contain;
		}
		
		padding: 0 26px;
	}
`;

const Agent = () => {
	const { t } = useTranslation();
	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='text-block pt-sans padding-80 font-20 color-grey'>
				{t('«АКАДЕМ-ОНЛАЙН» работает только с юридическими лицами. Для доступа к нашей системе бронирования необходимо заключить договор. Мы работаем как по договорам оказания услуг, так и по агентским договорам. Для желающих работать с нами в автоматическом режиме через интеграцию требует также подписание соглашения об XML-соединении. Образцы всех документов доступны для скачивания ниже.')}
			</div>

			<Docs className='text-block flex row wrap justify-between margin-bottom-40'>
				{/* <a href="/docs/ДОГОВОР ДОУ + согл о скидке 2024 - АОН.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'> */}
				<a href="/docs/ДОГОВОР ДОУ 2024 - АОН.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc} alt='' />
					{t('Договор оказания услуг')}
				</a>

				<a href="/docs/XML-СОГЛАШЕНИЕ  ДОУ АОН.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc2} alt='' />
					{t('Соглашение об XML-соединении к договору оказания услуг')}
				</a>

				<a href="/docs/АГЕНТСКИЙ ДОГОВОР 2024 - АОН.docx" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc} alt='' />
					{t('Агентский договор')}
				</a>

				<a href="/docs/XML-СОГЛАШЕНИЕ АГН АОН.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc2} alt='' />
					{t('Соглашение об XML-соединении к агентскому договору')}
				</a>

				<a href="/docs/АГЕНТСКИЙ ДОГОВОР АОН оплата на стойке.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc} alt='' />
					{t('Агентский договор с оплатой в отеле')}
				</a>

				<a href="/docs/XML-СОГЛАШЕНИЕ оплата в отеле 2022.doc" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40'>
					<img src={pictoDoc2} alt='' />
					{t('Соглашение об XML-соединении к агентскому договору с оплатой в отеле')}
				</a>

			</Docs>

			<div className='block-fullwidth bg-blue padding-80'>
				<p className='col-1 text-center color-white font-42 font-bold'>
					{t('Для подключения к системе отправьте запрос на')} <a href="mailto:rusmark@acase.ru" className='text-underline'>rusmark@acase.ru</a>
				</p>
			</div>

			<div className='block-fullwidth bg-blue'>
				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
};

export default Agent;