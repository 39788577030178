import { useLocalObservable } from 'mobx-react-lite';
import axios from 'axios';
import {action} from 'mobx';
// require('es6-promise').polyfill();

export const useMainStore = () => useLocalObservable(() => ({
	url: '/rapi99',
	headers: {
		'Content-Type': 'application/json; charset=UTF-8',
		'Bora-Alien': 2,
		'Bora-Formatted-Output': 1
	},

	isLoading: false,
	startLoading: action(function() { this.isLoading = true; }),
	stopLoading: action(function() { this.isLoading = false; }),

	counter: 0,
	addCounter: action(function() { this.counter += 1; }),
	delCounter: action(function() { this.counter -= 1; }),

	error: false,
	setError: action(function(error) { this.error = error; }),

	ajax(request, callback = () => {}) {
		this.startLoading();
		this.addCounter();
		this.setError(false);
		axios.post(this.url, request, {headers: this.headers})
			.then(data => {
				if (data.data.Error !== undefined) {
					const error = data.data.Error;
					const errors = [];
					errors.push('Ошибка сервиса!');
					error.Code && errors.push(`Код ошибки: ${error.Code}`);
					error.Description && errors.push(`Описание ошибки: ${error.Description}`);
					error.Pointer && errors.push(`Описание ошибки: ${error.Pointer}`);
					// console.log(errors);
					this.setError(errors);
				} else {
					return data.data;
				}
			})
			.then(callback)
			.catch(error => {
				if (error.response && error.response.status && error.response.status === 404) {
					this.setError(['Сервис временно недоступен.']);
				} else {
					// console.log(error.response)
				}
			})
			.finally(() => {
				this.delCounter();
				if (this.counter === 0) {
					this.stopLoading();
				}
			});
	},
	mail(data, callback = () => {}) {
		this.startLoading();
		this.addCounter();
		axios.post('/mail.php', data)
			.then(data => data.data)
			.then(callback)
			.catch(error => console.log(error))
			.finally(() => {
				this.delCounter();
				if (this.counter === 0) {
					this.stopLoading();
				}
			});
	}
}));