import React, {useEffect} from 'react';
import {Advantages, News} from 'components';
// import styled from "styled-components";
// import about1 from 'images/about-1.jpg';
// import about2 from 'images/about-2.jpg';
import pictoPhone from 'images/picto_phone2.svg';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const About = () => {
	const { t } = useTranslation();

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<div className='text-block padding-80 pt-sans color-grey font-20'>
				<p className='margin-bottom-40'>{t('«АКАДЕМ-ОНЛАЙН» входит в группу компаний «АКАДЕМСЕРВИС» — одного из лидеров российского туристического рынка. С момента своего основания в 1990 году «АКАДЕМСЕРВИС» превратился в поистине многопрофильную туристическую компанию. Системы онлайн-бронирования и MICE, GDS-провайдинг и туроператорская деятельность, трансферное обслуживание и специальные проекты — это далеко не полный перечень всего, что мы успели сделать за это время.')}</p>
				<p className='margin-bottom-40'>{t('Но современный туристический бизнес не стоит на месте. Чтобы идти в ногу со временем и развиваться еще динамичнее, руководством компании было принято решение о выделении в «АКАДЕМ-ОНЛАЙН» всего бизнеса, связанного с системами онлайн-бронирования гостиниц.')}</p>
				<p className='margin-bottom-40'>{t('Начав работать с 01 октября 2020 года, мы опираемся более чем 30-летний опыт успешной работы и руководствуемся единым основополагающим принципом — «нет ничего невозможного». В основе нашей системы уникальная контрактная база компании «АКАДЕМСЕРВИС» — почти 5000 отелей категорий 2-5* в России и ближнем зарубежье, надежные информационные технологии и опытный персонал.')}</p>
				<p className='margin-bottom-40'>{t('Мы уверенно смотрим в будущее и открыты для сотрудничества со всеми участниками рынка!')}</p>
				<p className='margin-bottom-40'>{t('Все дальнейшее развитие бизнеса систем онлайн-бронирования отелей будет проводиться уже в рамках «АКАДЕМ-ОНЛАЙН», а сама компания «АКАДЕМСЕРВИС» сосредоточится на развитии группового туризма, обслуживании спортивных команд, театральных коллективов, MICE и других не менее интересных туристических проектах.')}</p>

				<div className="text-block-full video-wrapper margin-bottom-80">
					<iframe src="https://www.youtube.com/embed/60rNtQqiHn0"
					        frameBorder="0"
					        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					        allowFullScreen
					        title={t('Академсервис: Выбирай лучшее')}
					/>
				</div>

				<Link to='/contacts' className='button'>
					<img src={pictoPhone} alt="" />
					{t('Контакты')}
				</Link>
			</div>

			<Advantages />

			<News />
		</>
	);
};

export default About;