import React from 'react';
import styled from 'styled-components';
import graphImg from 'images/graph.svg';
import {useInView} from 'react-intersection-observer';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	@media (min-width: 980px) {
		background: no-repeat url(${graphImg}) left center;
	}
	
	> div {
		@media (max-width: 659px) {
			> div:nth-child(n+2) { margin-top: 40px; }
		}
		
		@media (min-width: 660px) and (max-width: 979px) {
			> div:nth-child(n+3) { margin-top: 40px; }
		}
	}
`;

export const Advantages = () => {
	const { t } = useTranslation();

	const [ref, inView] = useInView({
		threshold: 0,
	});

	const items = [
		{
			count: 30,
			title: t('лет опыта')
		},
		{
			count: 5133,
			title: t('отеля в России и СНГ')
		},
		{
			count: 811,
			title: t('населенных пунктов')
		},
		{
			count: 17,
			title: t('Способов Интеграции')
		},
	];

	return (
		<Container className='block-fullwidth bg-blue padding-80'>
			<p className='font-16 color-white text-center text-uppercase margin-bottom-20'>{t('Надежность, опыт, технологичность')}</p>
			<p className='font-48 color-white text-center text-uppercase margin-bottom-80'>{t('Преимущества')}</p>

			<div className='col-1 flex row wrap justify-between ' ref={ref}>
				{items.map((i, key) => (
					<div className='col-4 color-white ' key={key}>
						<p className='text-center font-72 margin-bottom-20'>
							<CountUp duration={1} end={i.count} delay={0} separator=' ' useEasing={true} start={0} redraw={true}>
								{({ countUpRef, start }) => {
									if (inView) {
										start();
									}
									return <span ref={countUpRef}/>;
								}}
							</CountUp>
						</p>
						<p className='text-center font-16 text-uppercase'>{i.title}</p>
					</div>
				))}
			</div>
		</Container>
	);
};
