import { useLocalObservable } from 'mobx-react-lite';
import {action} from 'mobx';
import i18n from 'i18next';

export const useHotelStore = mainStore => useLocalObservable(() => ({
	hotel: {},
	setHotel: action(function(hotel) {
		this.hotel = hotel;
	}),
	markers: [],
	setMarkers: action(function(markers) { this.markers = markers; }),
	stars: [
		'',
		'https://images.acase.ru/signs_images/Level 5 grey.svg',
		'https://images.acase.ru/signs_images/Level 4 grey.svg',
		'https://images.acase.ru/signs_images/Level 3 grey.svg',
		'https://images.acase.ru/signs_images/Level 2 grey.svg',
		'https://images.acase.ru/signs_images/Level 1 grey.svg',
	],
	request(id) {
		return {
			HotelRequest: {
				UserType: "buyer",
				BuyerId: "RO1BAND",
				UserId: "WWW",
				Password: "84765",
				Language: i18n.language,
				ProfitCentreCode: "",
				SUserCode: "",
				IsSyncOtgr: "",
				ActionDescription: {
					Parameters: {
						HotelCode: id,
						BestEasternMember: 1
					}
				}
			}
		};
	},
	fetch(id) {
		mainStore.ajax(this.request(id), data => this.setHotel({...data.HotelResponse.Hotel[0], Images: {Image: data.HotelResponse.Hotel[0].Images.Image}}));
	},
	clear() {
		this.setHotel({});
		this.setMarkers([]);
	},

	fetchObjects(neLat, neLng, swLat, swLng, zoom) {
		const request = {
			MapRequest: {
				Language: "ru",
				ActionDestinationSearch: {
					Parameters: {
						Zoom: zoom,
						Bounds: {
							NorthEast: {
								Lat: neLat,
								Lng: neLng
							},
							SouthWest: {
								Lat: swLat,
								Lng: swLng
							}
						},
						Context: "003"
					}
				}
			}
		};
		mainStore.ajax(request, data => {
			// const group = data.MapResponse.DestinationGroupList.DestinationGroup[0];
			// console.log(group, group.DestinationTypeCode, group.ObjectTypeCode, data.Code);
			// console.log(group, this.getIconUri(parseInt(group.DestinationTypeCode), parseInt(group.ObjectTypeCode)));
			let markers = [];
			data.MapResponse.DestinationGroupList.DestinationGroup.forEach(g => {
				g.DestinationList.Destination.forEach(i => {
					markers.push({
						...i,
						icon: this.getIconUri(g.DestinationTypeCode, g.ObjectTypeCode, i.Code),
						stars: g.DestinationTypeCode === 3 && g.ObjectTypeCode === 9500001 ? this.stars[i.StarCode] : false
					});
				});
			});

			this.setMarkers(markers);
		});
	},

	getIconUri(destinationTypeCode, objectTypeCode, code) {
		//let color, countCurrentHotel, countDataHotelCode, countDataOfferAvailability;

		/*
		if (code === null) {
			code = 0;
		}
		 */

		const icon = destinationTypeCode + "-" + objectTypeCode + (destinationTypeCode === 3 && objectTypeCode === 9500001 ? (code === this.hotel.Code ? '-p' : '-b') : '');

		/*
		if (destinationTypeCode === 3 && objectTypeCode === 9500001) {
			countDataHotelCode = parseInt($('*[data-hotel-code="' + code + '"]').length);
			countDataOfferAvailability = countDataHotelCode === 1 ? parseInt($('*[data-hotel-code="' + code + '"]').find('*[data-hotel-offer-availability="2"]').length) : 0;
			countCurrentHotel = $('*[data-current-hotel-code="' + code + '"]').length;
			color = '-b';
			if (countCurrentHotel > 0) {
				color = '-p';
			} else if (countDataHotelCode === 1 && countDataOfferAvailability > 0) {
				color = '-g';
			} else if (countDataHotelCode === 1 && countDataOfferAvailability === 0) {
				color = '-y';
			}
			icon = "" + icon + color;
		}
		 */

		return "https://images.acase.ru/gmapIcons/" + icon + ".png";
	}
}));