import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import coverMain from 'images/cover-main-1.jpg';
import milanBg from 'images/milan-m.jpg';
import addHotelBg from 'images/laptop-phone-m.jpg';
import ipadBg from 'images/ipad-m.jpg';
import coffeeBg from 'images/coffee-m.jpg';

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	overflow: hidden;
`;

const Slide = styled.div`
	background-image: url(${props => props.$src});
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
	opacity: 0;
	visibility: visible;
	
	&.current {
		opacity: 1;
		visibility: visible;	
	}
`;

export const SlideShow = () => {
	const [ index, setIndex ] = useState(0);

	const slides = [
		coverMain,
		milanBg,
		addHotelBg,
		ipadBg,
		coffeeBg,
	];

	useEffect(() => {
		const timer = setTimeout(() => {
			setIndex(index === slides.length - 1 ? 0 : index + 1);
		}, 3000);
		return () => clearTimeout(timer);
	}, [index, slides.length]);

	return (
		<Container>
			{slides.map((i, key) => <Slide key={key} $src={i} className={index === key ? 'current' : ''} />)}
		</Container>
	);
};