import React, {useState} from 'react';
import styled from 'styled-components';
import {FullWidth} from './Media';
import {Button} from './index';
import { useTranslation } from 'react-i18next';

const PolicyContainer = styled.div`
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #AA1F00;
	color: white;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1005;
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	${props => props.$cookiesAgree && 'opacity: 0; visibility: hidden;'}
	
	> div {
		${FullWidth}
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		flex-direction: column;
		@media (min-width: 980px) {
			flex-direction: row;
		}

		> div {
			font-size: 16px;
			line-height: 21px;
			color: #FFFFFF;
			font-family: 'PT Sans', sans-serif !important;
			margin-right: 20px;
			
			@media (max-width: 979px) {
				margin-bottom: 20px;
			}
			
			@media (max-width: 539px) {
				margin-left: 20px;
				margin-right: 20px;
			}
		}
	}
`;

export const Policy = () => {
	const { t } = useTranslation();
	const [ cookiesAgree, setCookiesAgree ] = useState(localStorage.getItem('cookies_agree') === 'true');

	const handleClick = e => {
		e.preventDefault();
		localStorage.setItem('cookies_agree', 'true');
		setCookiesAgree(true);
	};

	return (
		<PolicyContainer $cookiesAgree={cookiesAgree}>
			<div>
				<div>
					{t('Мы используем файлы cookies и системы сбора статистики, чтобы сделать сайт удобнее. Продолжая пользоваться сайтом, Вы соглашаетесь на использование этой информации.')}
				</div>
				<Button borderWhite textWhite hoverWhite width={110} onClick={handleClick}>{t('Согласен')}</Button>
			</div>
		</PolicyContainer>
	);
};