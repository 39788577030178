import { useLocalObservable } from 'mobx-react-lite';
import i18n from 'i18next';

export const useCheckHotelStore = mainStore => useLocalObservable(() => ({
	hotels: null,
	hotel: null,

	request(needle) {
		return {
			DestinationRequest: {
				UserType: 'buyer',
				BuyerId: 'RO1BAND',
				UserId: 'WWW',
				Password: '84765',
				Language: i18n.language,
				ProfitCentreCode: '',
				SUserCode: '',
				IsSyncOtgr: '',
				ActionSearch: {
					Parameters: {
						Text: needle,
						HighlightCssClass: 'Highlighter',
						NotSearchableCssClass: 'NotSearchableCss',
						TypeCode: 3
					}
				}
			}
		};
	},

	requestHotel(id) {
		return {
			HotelRequest: {
				UserType: 'buyer',
				BuyerId: 'RO1BAND',
				UserId: 'WWW',
				Password: '84765',
				Language: i18n.language,
				ProfitCentreCode: '',
				SUserCode: '',
				IsSyncOtgr: '',
				ActionDescription: {
					Parameters: {
						HotelCode: id,
					}
				}
			}
		};
	},

	fetch(needle) {
		this.clear();
		mainStore.ajax(this.request(needle), data =>
			this.hotels = data.DestinationResponse.DestinationList.Destination
				.map(i => ({
					...i,
					Name: i.Name.replace(/(<([^>]+)>)/gi, '')
				}))
		);
	},

	fetchHotel(id) {
		mainStore.ajax(this.requestHotel(id), data => this.hotel = {...data.HotelResponse.Hotel[0], Images: {Image: data.HotelResponse.Hotel[0].Images.Image}});
	},

	clear() {
		this.hotels = null;
		this.hotel = null;
	}
}));