import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import arrowImg from 'images/arrow.svg';

const Container = styled.div`
	overflow: hidden;
	position: relative;
`;

const Next = styled.div`
	width: 18px;
	height: 30px;
	cursor: pointer;
	position: absolute;
	right: 0;
	z-index: 999;
	background-image: url(${arrowImg});
	background-size: contain;
	background-repeat: no-repeat;
	@media (max-width: 979px) { top: 55px; }
`;

const Prev = styled.div`
	width: 18px;
	height: 30px;
	cursor: pointer;
	position: absolute;
	left: 0;
	z-index: 999;
	background-image: url(${arrowImg});
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotateZ(180deg);
	@media (max-width: 979px) { top: 55px; }
`;

const Slides = styled.div`
	display: flex;
	flex-direction: row;
	transition: all .5s ease-in-out;
	
	> div {
		//@media (max-width: 539px) { padding: 0 20px; }
	}
	
	@media (min-width: 320px) { transform: translate(${props => (props.$width - 40) * -1 * props.$index}px); }
	@media (min-width: 540px) { transform: translate(${props => -460 * props.$index}px); }
	@media (min-width: 660px) { transform: translate(${props => -620 * props.$index}px); }
	@media (min-width: 980px) { transform: translate(${props => -940 * props.$index}px); }
	@media (min-width: 1280px) { transform: translate(${props => -1160 * props.$index}px); }
	@media (min-width: 1600px) { transform: translate(${props => -1400 * props.$index}px); }
`;

const Slide = styled.div`
	//width: 1160px;
	//@media (max-width: 1600px) { width: 940px; }
	//@media (max-width: 1280px) { width: 780px; }
	
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: column;
	@media (min-width: 980px) { flex-direction: row; }
	
	padding: 0;
	@media (min-width: 980px) { padding: 0 80px; }
	@media (min-width: 1280px) { padding: 0 110px; }
	@media (min-width: 1600px) { padding: 0 120px; }
	${props => props.$width < 540 && `width: ${props.$width - 40}px !important;`}
	
	h2 {
		font-family: 'PT Sans', sans-serif !important;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 10px;
		@media (min-width: 980px) {
			font-size: 24px;
			line-height: 31px;
			margin-bottom: 20px;
		}
		color: #333333;
	}
	
	h3 {
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		@media (min-width: 980px) {
			font-size: 18px;
			line-height: 23px;
		}
		color: #034781;
		font-family: 'PT Sans', sans-serif !important;
	}

	img {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0 auto 15px auto;
		@media (min-width: 980px) { margin: 0 20px 0 0; }
		@media (min-width: 1280px) { margin: 0 40px 0 0; }
		
		@media (max-width: 1600px) {
			width: 160px;
			height: 160px; 
		}
		
		@media (max-width: 1280px) {
			width: 140px;
			height: 140px; 
		}
	}
`;

export const Carousel = ({slides = [], className = ''}) => {
	const [ index, setIndex ] = useState(0);
	const [ width, setWidth ] = useState(0);

	const set = () => setWidth(document.getElementsByTagName('body')[0].getBoundingClientRect().width);

	useEffect(() => {
		set();
		window.addEventListener('resize', set);
		return () => window.removeEventListener('resize', set);
	}, []);

	const handleNext = () => setIndex(index < slides.length - 1 ? index + 1 : 0);

	const handlePrev = () => setIndex(index > 0 ? index - 1 : slides.length - 1);

	return (
		<Container className={'col-1 flex row align-center justify-between ' + className} id="special">
			<Prev onClick={handlePrev} />

			<Slides $index={index} $count={slides.length} $width={width}>
				{slides.map((i, key) => <Slide className='col-1' key={key} $width={width}>
					<img src={`/images/recommends/${i.img}`} alt="" />
					<div>
						<h2>{i.text}</h2>
						<h3>{i.name}</h3>
						<h3>{i.position}</h3>
					</div>
				</Slide>)}
			</Slides>

			<Next onClick={handleNext} />
		</Container>
	);
};