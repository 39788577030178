import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import React from 'react';
import './reset.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import './i18n';
import { NewsProvider } from 'store/useNewsStore';

const container = document.getElementById('root');

configure({ useProxies: 'ifavailable' });

const root = createRoot(container);
root.render(
	<BrowserRouter>
		<NewsProvider>
			<App/>
		</NewsProvider>
	</BrowserRouter>,
);
