import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useNewsStore} from 'store/useNewsStore';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const NewsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	
	@media (min-width: 980px) {
		flex-direction: row;
		justify-content: space-between;
		
		> * {
			&:nth-child(4) { display: none; }
		}
	}
	
	> * {
		@media (max-width: 539px) { width: 100% !important; }
	
		@media (min-width: 660px) and (max-width: 979px) {
			width: 300px !important;
			
			&:nth-child(2n) {
				margin-left: 20px;
			}
			
			&:nth-child(n+3) {
				margin-top: 20px;
			} 
		}
		
		@media (max-width: 659px) {
			&:nth-child(n+2) {
				margin-top: 20px;
			}
		}
		
		div {
			position: relative;
			line-height: 0;
			padding-top: 75% !important;

			&:before {
				content: '';
				z-index: 110;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(3, 71, 129, 0.6);
				opacity: 0;
				transition: opacity .2s ease-in-out;
			}
			
			img {
				z-index: 100;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
			}
		}
		
		&:hover {
			div:before {
				opacity: 1;
			}
		}
		
	}
`;

export const News = ({withSubtitle = false}) => {
	const { t } = useTranslation();
	const store = useNewsStore();
	const locale = i18n.language;

	const {items, lastItems} = store;

	return (
		<div className='col-1 padding-80 flex column align-center'>
			{withSubtitle && <p className='font-16 text-blue text-center text-uppercase margin-bottom-20'>{t('Будь в курсе')}</p>}
			<p className='font-48 text-blue text-center text-uppercase margin-bottom-40'>{t('Новости')}</p>

			<NewsContainer className='margin-bottom-80 col-1'>
				{lastItems.map((i, key) =>
					<Link to={`/news/${i.id}`} className='col-3 color-blue hover' key={key}>
						<div className='margin-bottom-20'>
							{locale !== 'ru' && !!i?.enSmallImg && <img src={i.enSmallImg} alt="" />}
							{(locale === 'ru' || !i?.enSmallImg) && <img src={i.smallImg} alt="" />}
						</div>
						<p className='font-16 margin-bottom-10'>{i.date}</p>
						<p className='font-24'>
							{(locale !== 'ru' && !!i?.enTitle) ? i.enTitle : i.title}
						</p>
					</Link>
				)}

				{lastItems.length < 3 && new Array(3 - lastItems.length).fill(0).map((i, key) =>
					<div className='col-3' key={key} />
				)}
			</NewsContainer>

			{items.length > 3 && <Link to='/news' className='button-white col-3'>{t('Другие новости')}</Link>}
		</div>
	);
};